import { createContext } from 'react';

import { FileScanner, ImageDataCache, UploadPipeline } from './services';

export const UploaderContext = createContext<{
  uploadPipeline: UploadPipeline;
  fileScanner: FileScanner;
  thumbnailCache: ImageDataCache;
} | null>(null);

UploaderContext.displayName = 'UploaderContext';
