import { ComponentType, memo, useCallback, useMemo, useState } from 'react';

import { faEye, faFile, faFileLines, faPen, faShare, faShareNodes, faUser, faUserDoctor } from '@fortawesome/pro-solid-svg-icons';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridCellProps, GridColumn, GridColumnReorderEvent, GridDataStateChangeEvent, GridRowDoubleClickEvent } from '@progress/kendo-react-grid';
import { SwitchChangeEvent } from '@progress/kendo-react-inputs';
import { MenuSelectEvent } from '@progress/kendo-react-layout';
import { Offset } from '@progress/kendo-react-popup';
import { pickBy } from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { ExamModel } from 'models';

import { useBoolean, useEvent, useMemoizedComponent, useOutsideClick } from 'core/hooks';
import {
  Action,
  ActionListCell,
  Button,
  ButtonVariants,
  ComponentSizes,
  DEFAULT_PAGE_SIZES,
  DataTable,
  HeaderCell,
  Label,
  Popup,
  SelectedState,
  Switch,
  Toolbar,
} from 'core/ui';
import { Tooltip } from 'core/ui/Tooltip';

import { useUserSettings } from 'features/settings';

import { ActivityLogModal } from '../../activity-log';
import { ExamStatusLogModal } from '../../exam-status-log';
import { ContextMenuItems } from '../constants';
import { ExamsGridService } from '../services';
import { ChangeStatusModalProps, ColumnState, ExamGridProps } from '../types';
import { BulkAssignModal } from './BulkAssignModal';
import { BulkCancelModal } from './BulkCancelModal';
import { ChangeStatusModal } from './ChangeStatusModal';
import { ColumnsModal } from './ColumnsModal';
import { ExamGridHeaderCell } from './ExamGridHeaderCell';
import { FilterModal } from './FilterModal';
import { SearchInput } from './SearchInput';
import { ThumbnailColumnExamGrid } from './ThumbnailColumnExamGrid';

const DEFAULT_EMPTY_FILTER: CompositeFilterDescriptor = { filters: [], logic: 'and' };

const PAGEABLE_SETTINGS = { pageSizes: DEFAULT_PAGE_SIZES };

const DEFAULT_CONTEXT_MENU_OFFSET: Offset = { left: 0, top: 0 };

const EMPTY_EXAM_SELECTION: SelectedState = {};

export const ExamGrid = memo<ExamGridProps>(
  ({
    featureView,
    data,
    columnsState,
    dataState,
    showActionColumn = true,
    selectable = true,
    filterable = true,
    selectedExams = EMPTY_EXAM_SELECTION,
    filterActive = true,
    filterSearch,
    isLoading = false,
    showExamToolbar = false,
    showPatientToolbar = false,
    showToolbar = true,
    slotToolbarPrefix,
    onExamViewFinalReportClick,
    onExamViewAttachmentsClick,
    onExamViewClick,
    onExamReadClick,
    onExamReadRequestClick,
    onExamEditClick,
    onExamDoubleClick,
    onExamShareChange,
    onExamShareClick,
    onExamManagePatientClick,
    onBulkChangeStatusSubmit,
    onBulkCancelSubmit,
    onBulkAssignSubmit,
    onAddNewClick,
    onExportCsvClick,
    onFilterActiveChange,
    onFilterSearchChange,
    onFilterSearchSubmit,
    onDataStateChange,
    onColumnsStateChange,
    onSelectedExamsChange,
  }) => {
    const { reactReadRequestPage, reactPatientFormPage } = useUserSettings(true);

    const [isActivityLogModalOpen, { setTrue: openActivityLogModal, setFalse: closeActivityLogModal }] = useBoolean(false);
    const [isStatusLogModalOpen, { setTrue: openStatusLogModal, setFalse: closeStatusLogModal }] = useBoolean(false);
    const [isChangeStatusModalOpen, { setTrue: openChangeStatusModal, setFalse: closeChangeStatusModal }] = useBoolean(false);
    const [isColumnsModalOpen, { setFalse: openColumnsModal, toggle: closeColumnsModal }] = useBoolean(false);
    const [isFilterModalOpen, { toggle: toggleIsFilterModalOpen, setFalse: closeFilterModal }] = useBoolean(false);
    const [isBulkAssignModalOpen, { setTrue: openBulkAssignModal, setFalse: closeBulkAssignModal }] = useBoolean(false);
    const [isBulkCancelModalOpen, { setFalse: closeBulkCancelModal, setTrue: openBulkCancelModal }] = useBoolean(false);
    const [isContextMenuOpen, { setTrue: openContextMenu, setFalse: closeContextMenu }] = useBoolean(false);
    const [contextMenuOffset, setContextMenuOffset] = useState<Offset>(DEFAULT_CONTEXT_MENU_OFFSET);
    const [contextMenuExam, setContextMenuExam] = useState<ExamModel | null>(null);
    const [statusChangeOption, setStatusChangeOption] = useState<string | null>(null);

    const hasSelectedExams = selectedExams == null ? false : Object.values(selectedExams).some((isSelected) => isSelected);

    const { gridColumns, filterColumns } = useMemo(() => {
      switch (featureView) {
        case 'Exam':
          return {
            gridColumns: ExamsGridService.getGridColumns(),
            filterColumns: ExamsGridService.getColumns(),
          };
        case 'Patient':
          return {
            gridColumns: ExamsGridService.getGridColumnsDonor(),
            filterColumns: ExamsGridService.getColumnsDonor(),
          };
        case 'Share':
          return {
            gridColumns: ExamsGridService.getGridColumnsDonorShare(),
            filterColumns: ExamsGridService.getColumnsDonorShare(),
          };
        case 'Merge-Donor':
          return {
            gridColumns: ExamsGridService.getGridColumnsMergeDonor(),
            filterColumns: ExamsGridService.getColumnsMergeDonor(),
          };
        case 'Physician':
          return {
            gridColumns: ExamsGridService.getGridColumnsPhysicianDashboard(),
            filterColumns: ExamsGridService.getColumnsPhysicianDashboard(),
          };
      }
    }, [featureView]);

    const passesBulkAssignValidation = useMemo(() => {
      const selectedExamIds = Object.keys(selectedExams).filter((examId) => selectedExams[examId]);
      const exams = Array.isArray(data) ? data : data?.data;
      const selectedExamsData = exams?.filter((exam) => selectedExamIds.includes(exam?.id?.toString() ?? ''));

      // Check if all selected exams have the required status types
      return selectedExamsData ? selectedExamsData.every((exam) => exam.statusType === 'Requested' || exam.statusType === 'ReadyToAssign') : false;
    }, [selectedExams, data]);

    const handleContextMenuOpen = useEvent((event: MouseEvent, dataItem: ExamModel) => {
      if (featureView == 'Exam') {
        setContextMenuExam(dataItem);
        setContextMenuOffset({ left: event.clientX, top: event.clientY });
        openContextMenu();
      }
    });

    const contextMenuRef = useOutsideClick<HTMLDivElement>(() => {
      if (isContextMenuOpen) {
        setContextMenuExam(null);
        setContextMenuOffset(DEFAULT_CONTEXT_MENU_OFFSET);
        closeContextMenu();
      }
    });

    const handleOnContextMenuSelect = useEvent((event: MenuSelectEvent) => {
      if (event.item.text === ContextMenuItems.activityLogs.text) {
        openActivityLogModal();
      } else if (event.item.text === ContextMenuItems.examStatusLogs.text) {
        openStatusLogModal();
      } else {
        openChangeStatusModal();
        setStatusChangeOption(event.item.text ?? null);
      }

      closeContextMenu();
    });

    const handleFilterModalSave = useEvent((filter: CompositeFilterDescriptor) => {
      closeFilterModal();

      onDataStateChange?.({
        dataState: { ...dataState, filter },
        saveExpectation: 'browser-only',
        refreshExpectation: 'now',
      });
    });

    const handleColumnsReorder = useEvent(({ columns }: GridColumnReorderEvent) => {
      const newColumnsState = ExamsGridService.updateColumnsOrder(columnsState, columns);

      onColumnsStateChange?.({
        columnsState: newColumnsState,
        saveExpectation: 'browser-only',
      });
    });

    const handleColumnsModalSave = useEvent((newColumnsState: Record<string, ColumnState>) => {
      onColumnsStateChange?.({
        columnsState: newColumnsState,
        saveExpectation: 'browser-only',
      });
    });

    const handleChangeStatusSubmit: ChangeStatusModalProps['onSubmit'] = useEvent((change) => {
      closeChangeStatusModal();
      onBulkChangeStatusSubmit?.(change);
    });

    const handleExamShareClick = useEvent((data: ExamModel) => {
      if (data.id == null) throw new Error("Cannot share exam when the exam's Id property is null or undefined.");
      if (data.patientId == null) throw new Error('Cannot share exam when the patient_Id property is null or undefined.');

      onExamShareClick?.(data);
    });

    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    const gridActions: Action[] = useMemo(() => {
      if (featureView === 'Patient') {
        return [
          {
            key: 'edit-exam',
            title: 'Edit Exam',
            icon: faPen,
            onClick: (_, dataItem) => onExamEditClick?.(dataItem),
          },
          {
            key: 'view-files-images',
            title: 'View Files Images',
            icon: faEye,
            onClick: (_, dataItem) => onExamViewClick?.(dataItem),
            disabled: (dataItem: ExamModel) => dataItem.patientFilesExternalSource === null,
          },
          {
            key: 'share-exam',
            title: 'Share Exam',
            icon: faShareNodes,
            onClick: (_, dataItem) => handleExamShareClick(dataItem),
          },
          {
            key: 'view-attachments',
            title: 'View/Add Attachments',
            icon: faFile,
            onClick: (_, dataItem) => onExamViewAttachmentsClick?.(dataItem),
          },
          {
            key: 'view-read-request',
            title: 'Read Request',
            icon: faUserDoctor,
            onClick: (_, dataItem) => onExamReadClick?.(dataItem),
          },
          {
            key: 'view-final-report',
            title: 'View Final Report',
            icon: faFileLines,
            onClick: (_, dataItem) => onExamViewFinalReportClick?.(dataItem),
            disabled: (dataItem: ExamModel) => !dataItem.files.some((file) => file.fileName?.toLowerCase() === 'finalreport.pdf'),
          },
        ];
      }

      if (featureView === 'Share') {
        return [
          {
            key: 'view-files-images',
            title: 'View Files Images',
            icon: faEye,
            onClick: (_, dataItem) => onExamViewClick?.(dataItem),
            disabled: (dataItem: ExamModel) => dataItem.patientFilesExternalSource === null || dataItem.patientFilesExternalSource === false,
          },
          {
            key: 'view-attachments',
            title: 'View Attachments',
            icon: faFile,
            onClick: (_, dataItem) => onExamViewAttachmentsClick?.(dataItem),
            disabled: (dataItem: ExamModel) => {
              return dataItem.files.length === 0;
            },
          },
        ];
      }

      if (featureView === 'Physician') {
        return [
          {
            key: 'read-exam',
            title: 'Read Exam',
            icon: faFileLines,
            onClick: (_, dataItem) => onExamReadClick?.(dataItem),
          },
        ];
      }

      const newActions: Action[] = [
        {
          key: 'edit-exam',
          title: 'Edit Exam',
          icon: faPen,
          onClick: (_, dataItem) => onExamEditClick?.(dataItem),
        },
      ];

      if (reactReadRequestPage) {
        newActions.push({
          key: 'read-request',
          title: 'Read Request',
          icon: faUserDoctor,
          onClick: (_, dataItem) => onExamReadRequestClick?.(dataItem),
        });
      }

      newActions.push({
        key: 'manage-patient',
        title: 'Manage Patient',
        icon: faUser,
        onClick: (_, dataItem) => onExamManagePatientClick?.(dataItem),
      });

      return newActions;
    }, [
      featureView,
      handleExamShareClick,
      onExamEditClick,
      onExamManagePatientClick,
      onExamReadClick,
      onExamReadRequestClick,
      onExamViewAttachmentsClick,
      onExamViewClick,
      onExamViewFinalReportClick,
      reactReadRequestPage,
    ]);

    const thumbnailColumnCell = useCallback(
      (cellProps: GridCellProps) => {
        return <ThumbnailColumnExamGrid {...cellProps} width="24px" height="24px" onClick={onExamEditClick} />;
      },
      [onExamEditClick],
    );

    const handleBulkCancelSave = useEvent((reason: string) => {
      if (selectedExams == null) throw new Error('Cannot bulk cancel exams when the selectedExams property is null or undefined.');

      onBulkCancelSubmit?.(selectedExams, reason);
      onSelectedExamsChange?.({});
      closeBulkCancelModal();
    });

    const handleBulkAssignSubmit = useEvent((physicianId: number) => {
      onBulkAssignSubmit?.(physicianId);
      onSelectedExamsChange?.({});
      closeBulkAssignModal();
    });

    const handleDataStateChange = useEvent((event: GridDataStateChangeEvent) => {
      let isEventFromPager = false;
      let isEventFromSortClick = false;

      // Determine if the event originates within the pager.
      if (event.syntheticEvent.target instanceof Element) {
        isEventFromPager = event.syntheticEvent.target.closest('.k-pager') != null;
      }

      // Determine if the event is the result of the user clicking a sortable header cell.
      if (event.syntheticEvent.type === 'click' && event.syntheticEvent.target instanceof Element) {
        // We have to make sure we are within the separate <table> element for the headers, but we also have to make sure we aren't in the filter row.
        isEventFromSortClick =
          event.syntheticEvent.target.closest('.k-grid-header-table') != null && event.syntheticEvent.target.closest('.k-filter-row') == null;
      }

      onDataStateChange?.({
        dataState: event.dataState,
        saveExpectation: 'browser-only',
        refreshExpectation: isEventFromPager || isEventFromSortClick ? 'now' : 'debounce',
      });
    });

    const handleExamDoubleClick = useEvent((event: GridRowDoubleClickEvent) => {
      onExamDoubleClick?.(event.dataItem);
    });

    const ShareCellRender = useMemoizedComponent<GridCellProps>(
      ({ dataItem }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const handleCellValueChange = useEvent(() => onExamShareChange?.(dataItem));

        return (
          <td>
            <Switch onChange={handleCellValueChange} value={dataItem.share} />
          </td>
        );
      },
      [onExamShareChange],
      'ShareCellRender',
    );

    const NotesCellRender = useMemoizedComponent<GridCellProps>(
      ({ dataItem }) => {
        const maxLength = 250;
        const value = dataItem.notes as string | undefined;
        if (value && value.length > maxLength) {
          return <td title={value}>{value?.slice(0, maxLength) + '...'}</td>;
        } else {
          return <td title={value}>{value}</td>;
        }
      },
      [],
      'CellWithTooltip',
    );

    return (
      <>
        <ActivityLogModal show={isActivityLogModalOpen} entityType="exam" id={contextMenuExam?.id} onClose={closeActivityLogModal} />
        <ExamStatusLogModal show={isStatusLogModalOpen} id={contextMenuExam?.id} onClose={closeStatusLogModal} />
        <ChangeStatusModal
          show={isChangeStatusModalOpen}
          examId={contextMenuExam?.id}
          onClose={closeChangeStatusModal}
          onSubmit={handleChangeStatusSubmit}
          statusChangeOption={statusChangeOption}
        />
        <BulkCancelModal show={isBulkCancelModalOpen} selectedExams={selectedExams} onClose={closeBulkCancelModal} onSave={handleBulkCancelSave} />
        <ColumnsModal show={isColumnsModalOpen} columns={gridColumns} columnsState={columnsState} onClose={openColumnsModal} onSave={handleColumnsModalSave} />
        <FilterModal
          show={isFilterModalOpen}
          columns={filterColumns}
          initialFilter={dataState.filter ?? DEFAULT_EMPTY_FILTER}
          onClose={closeFilterModal}
          onSave={handleFilterModalSave}
        />
        {selectedExams && (
          <BulkAssignModal show={isBulkAssignModalOpen} selectedExams={selectedExams} onClose={closeBulkAssignModal} onSubmit={handleBulkAssignSubmit} />
        )}
        <Popup ref={contextMenuRef} onSelect={handleOnContextMenuSelect} isOpen={isContextMenuOpen} menuItems={ContextMenuItems} offset={contextMenuOffset} />
        <DataTable
          {...dataState}
          data={data}
          onContextMenuOpen={handleContextMenuOpen}
          onRowDoubleClick={handleExamDoubleClick}
          onColumnReorder={handleColumnsReorder}
          pageable={PAGEABLE_SETTINGS}
          filterable={filterable}
          reorderable
          sortable
          resizable
          selectable={selectable}
          selectedState={selectedExams}
          onSelectionChange={onSelectedExamsChange}
          size={ComponentSizes.SMALL}
          total={data != null && 'total' in data ? data.total : data?.length}
          onDataStateChange={handleDataStateChange}
          isLoading={isLoading}
          search={filterSearch}
          actions={gridActions}
        >
          {showToolbar && (
            <Toolbar
              customElements={
                <>
                  <StyledToolbarLeftDiv>
                    {slotToolbarPrefix}
                    <StyledSearchInput placeholder="Search..." value={filterSearch ?? ''} onChange={onFilterSearchChange} onSubmit={onFilterSearchSubmit} />
                    <StyledActionButton type="button" onClick={toggleIsFilterModalOpen} variant={ButtonVariants.SECONDARY}>
                      Filter
                    </StyledActionButton>

                    <StyledActionButton type="button" onClick={closeColumnsModal} variant={ButtonVariants.SECONDARY}>
                      Columns
                    </StyledActionButton>

                    <StyledActionButton type="button" onClick={onExportCsvClick} variant={ButtonVariants.SECONDARY}>
                      Export as CSV
                    </StyledActionButton>
                  </StyledToolbarLeftDiv>

                  {showExamToolbar && (
                    <StyledToolbarRightDiv>
                      <Tooltip text={passesBulkAssignValidation ? undefined : 'Notice - one or more selected studies are not in "Requested" status.'}>
                        <span>
                          <StyledActionButton
                            disabled={_isEmpty(pickBy(selectedExams, (isSelected) => isSelected)) || !passesBulkAssignValidation}
                            onClick={openBulkAssignModal}
                            variant={ButtonVariants.SECONDARY}
                          >
                            Bulk assign
                          </StyledActionButton>
                        </span>
                      </Tooltip>

                      <StyledActionButton disabled={!hasSelectedExams} onClick={() => openBulkCancelModal()}>
                        Bulk Cancel
                      </StyledActionButton>

                      <StyledAddNewButton onClick={onAddNewClick}>+ Add New</StyledAddNewButton>
                    </StyledToolbarRightDiv>
                  )}
                </>
              }
            />
          )}

          {featureView === 'Share' ? (
            <GridColumn
              field=""
              filterable={false}
              headerCell={HeaderCell}
              reorderable={false}
              sortable={false}
              title=""
              cell={thumbnailColumnCell}
              width={50}
            />
          ) : (
            ''
          )}
          {showActionColumn && (
            <GridColumn
              filterable={false}
              headerCell={HeaderCell}
              reorderable={false}
              sortable={false}
              title="Action"
              cell={ActionListCell as ComponentType<GridCellProps>}
              width={gridActions.length * 30}
            />
          )}
          {gridColumns.map((column) => {
            // check column orders
            const { orderIndex, show } = ExamsGridService.getColumnState(columnsState, column.field, featureView);

            if (!show) {
              return null;
            }
            if (column.field == 'notes') {
              return (
                <GridColumn
                  key={column.field}
                  {...column}
                  headerCell={ExamGridHeaderCell}
                  orderIndex={orderIndex}
                  cell={NotesCellRender}
                  filter={column.columnFilter}
                />
              );
            }
            if (column.field === 'share') {
              return (
                <GridColumn
                  key={column.field}
                  {...column}
                  headerCell={ExamGridHeaderCell}
                  orderIndex={orderIndex}
                  cell={ShareCellRender}
                  filter={column.columnFilter}
                />
              );
            }

            return (
              <GridColumn
                key={column.field}
                {...column}
                headerCell={ExamGridHeaderCell}
                orderIndex={orderIndex}
                cell={column.cell}
                filter={column.columnFilter}
              />
            );
          })}
        </DataTable>
      </>
    );
  },
);

ExamGrid.displayName = 'ExamGrid';

const StyledToolbarLeftDiv = styled.div`
  display: flex;
  flex: 1 1 0;
`;

const StyledToolbarRightDiv = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

const StyledSearchInput = styled(SearchInput)`
  display: flex;
  flex: 0 0 400px;
  width: 400px;
  margin-right: ${({ theme }) => theme.space.spacing20};
`;

const StyledActionButton = styled(Button)`
  margin-right: ${({ theme }) => theme.space.spacing20};
`;

const StyledAddNewButton = styled(Button)``;
