import { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';

import { useMediaQuery } from '@uidotdev/usehooks';
import { ThemeProvider as StyledThemeProvider, createGlobalStyle, css } from 'styled-components';

import { BreakpointContext } from './contexts';
import { BreakpointClassNames, theme } from './theme';
import './theme.scss';
import { BreakpointContextType } from './types';

const GlobalStyle = createGlobalStyle`
  html.${BreakpointClassNames.Desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;

    body {
      overflow: hidden;
    }

    #root {
      overflow: hidden;
    }
  }

  html.${BreakpointClassNames.Desktop}.${BreakpointClassNames.DesktopShort} {
    grid-template-rows: 768px;
    overflow-y: scroll;
  }

  html.${BreakpointClassNames.Mobile} {
    grid-template-columns: minmax(360px, 100dvw);
    grid-template-rows: minmax(100dvh, 1fr);
  }

  html.${BreakpointClassNames.Mobile}.${BreakpointClassNames.VerticalClamp} {
    grid-template-rows: 100dvh;

    body {
      overflow: hidden;
    }

    #root {
      overflow: hidden;
    }
  }

  html {
    font-size: 11px;
    display: grid;
    height: 100%;
  }

  body {
    font-family: ${(props) => props.theme.fontFamilies.body};
    background: ${(props) => props.theme.colors.palette.grayscale[3]};
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  #root {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  // The default z-index for Kendo popups, tooltips, dropdown lists, etc is 100.  This causes any overlay elements that are inside a Bootstrap modal to appear underneath the overlay.
  // This overrides the z-index so it is compatible with Bootrap.  See: https://www.telerik.com/kendo-react-ui/components/popup/stack-order/
  .k-animation-container {
    z-index: 10003 !important;
  }

  // This overrides the default Kendo theme colors to match the theme colors of the application.
  .k-list-item.k-selected,
  .k-selected.k-list-optionlabel {
    background-color: ${(props) => props.theme.colors.primary};
  }
  .k-focus.k-selected:hover {
    background-color: ${(props) => props.theme.colors.primary};
  }

  svg.svg_primary {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const ThemeProvider: FunctionComponent<{
  children?: ReactNode;
}> = ({ children }) => {
  const breakpointDesktop = useMediaQuery('only screen and (min-width: 1024px)');
  const breakpointDesktopShort = useMediaQuery('only screen and (min-width: 1024px) and (max-height: 767.98px)');
  const breakpointMobile = useMediaQuery('only screen and (max-width: 1023.98px)');
  const orientationPortrait = useMediaQuery('only screen and (orientation: portrait)');

  const [verticalClampOverride, setVerticalClampOverride] = useState(false);

  const breakpointContext: BreakpointContextType = useMemo(
    () => ({
      desktop: breakpointDesktop,
      desktopShort: breakpointDesktopShort,
      mobile: breakpointMobile,
      portrait: orientationPortrait,
      landscape: !orientationPortrait,
      verticalClamp: breakpointDesktop || verticalClampOverride,
      setVerticalClampOverride,
    }),
    [breakpointDesktop, breakpointDesktopShort, breakpointMobile, orientationPortrait, verticalClampOverride],
  );

  useEffect(() => {
    const addClasses: string[] = [];
    const removeClasses: string[] = [];

    if (breakpointDesktop) {
      addClasses.push(BreakpointClassNames.Desktop);
    } else {
      removeClasses.push(BreakpointClassNames.Desktop);
    }

    if (breakpointDesktopShort) {
      addClasses.push(BreakpointClassNames.DesktopShort);
    } else {
      removeClasses.push(BreakpointClassNames.DesktopShort);
    }

    if (breakpointMobile) {
      addClasses.push(BreakpointClassNames.Mobile);
    } else {
      removeClasses.push(BreakpointClassNames.Mobile);
    }

    if (orientationPortrait) {
      addClasses.push(BreakpointClassNames.OrientationPortrait);
      removeClasses.push(BreakpointClassNames.OrientationLandscape);
    } else {
      removeClasses.push(BreakpointClassNames.OrientationPortrait);
      addClasses.push(BreakpointClassNames.OrientationLandscape);
    }

    if (verticalClampOverride) {
      addClasses.push(BreakpointClassNames.VerticalClamp);
    } else {
      removeClasses.push(BreakpointClassNames.VerticalClamp);
    }

    if (addClasses.length > 0) {
      document.documentElement.classList.add(...addClasses);
    }
    if (removeClasses.length > 0) {
      document.documentElement.classList.remove(...removeClasses);
    }
  }, [breakpointDesktop, breakpointDesktopShort, breakpointMobile, orientationPortrait, verticalClampOverride]);

  return (
    <BreakpointContext.Provider value={breakpointContext}>
      <StyledThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </StyledThemeProvider>
    </BreakpointContext.Provider>
  );
};

ThemeProvider.displayName = 'ThemeProvider';
