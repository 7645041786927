import { useRef } from 'react';

import _map from 'lodash/map';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { Field, FormValidatorsService } from 'core/forms';
import { Accordion, ChipList, DropdownWithValuesField, TextArea } from 'core/ui';

import { ReadingTypes } from '../../exam/constants';
import WriteIcon from '../assets/write.svg?react';
import {
  CentrilobularNecrosisOptions,
  FibrosisOptions,
  PortalInfiltratesOptions,
  SATISFACTORY_SAMPLE_SIZE_OPTIONS,
  TypeVesculiarOptions,
  YesNoUnknownOptions,
} from '../constants';
import { useInterpretableState } from '../hooks/useInterpretableState';
import { PathologyTargetedAccordionBody } from './PathologyTargetedAccordionBody';

// has to be wrapped in <Form /> to work
const PathologyLiverResultsAccordion = ({ eventKey, isFormVisible, valueGetter, valueSetter }) => {
  const readingType = valueGetter('Results.readingType');
  const satisfactorySampleSize = SATISFACTORY_SAMPLE_SIZE_OPTIONS.find((option) => option.value === valueGetter('Results.satisfactorySampleSize'));

  const accordionBodyRef = useRef(null);
  const isUninterpretable = useInterpretableState(valueGetter, valueSetter, accordionBodyRef);

  let accordionBody;

  if (isFormVisible && readingType === ReadingTypes.GENERAL.value) {
    accordionBody = (
      <Accordion.Body>
        <StyledRow>
          <Col className="col-12">
            <Field
              component={DropdownWithValuesField}
              data={_map(FibrosisOptions)}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Fibrosis"
              name="Results.fibrosis"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>
          <Col className="col-12">
            <Field
              component={DropdownWithValuesField}
              data={SATISFACTORY_SAMPLE_SIZE_OPTIONS}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Satisfactory Sample"
              name="Results.satisfactorySampleSize"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col className="col-12">
            <Field
              component={DropdownWithValuesField}
              data={_map(PortalInfiltratesOptions)}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Portal Infiltrates"
              name="Results.portalInfiltrates"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col className="col-6">
            <Field
              component={DropdownWithValuesField}
              data={_map(TypeVesculiarOptions)}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Type Microvesculiar"
              name="Results.typeMicrovesculiar"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>
          <Col className="col-6">
            <Field
              component={DropdownWithValuesField}
              data={_map(TypeVesculiarOptions)}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Type Macrovesculiar"
              name="Results.typeMacrovesculiar"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col className="col-12">
            <Field
              component={DropdownWithValuesField}
              data={_map(CentrilobularNecrosisOptions)}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Centrilobular Necrosis"
              name="Results.centrilobularNecrosis"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col className="col-6">
            <Field
              component={ChipList}
              data={_map(YesNoUnknownOptions)}
              label="Cholestasis"
              name="Results.cholestasis"
              selection="single"
              required
              validator={FormValidatorsService.required}
            />
          </Col>
          <Col className="col-6">
            <Field
              component={ChipList}
              data={_map(YesNoUnknownOptions)}
              label="Lipofuscin"
              name="Results.lipofuscin"
              selection="single"
              required
              validator={FormValidatorsService.required}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col className="col-6">
            <Field
              component={ChipList}
              data={_map(YesNoUnknownOptions)}
              label="Active Lobular Inflammation"
              name="Results.activeLobularInflammation"
              selection="single"
              required
              validator={FormValidatorsService.required}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col className="col-12">
            <Field
              component={TextArea}
              label="Comments"
              name="Results.comments"
              warning={satisfactorySampleSize?.requiresCommentsMessage ?? undefined}
              required={isUninterpretable || satisfactorySampleSize?.requiresComments}
              validator={isUninterpretable || satisfactorySampleSize?.requiresComments ? FormValidatorsService.required : undefined}
            />
          </Col>
        </StyledRow>
      </Accordion.Body>
    );
  } else if (isFormVisible && readingType === ReadingTypes.TARGETED.value) {
    accordionBody = <PathologyTargetedAccordionBody isUninterpretable={isUninterpretable} valueGetter={valueGetter} />;
  }
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={WriteIcon} title="Results" />
      <div ref={accordionBodyRef}>{accordionBody}</div>
    </Accordion.Item>
  );
};

const StyledRow = styled(Row)`
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.space.spacing30};
  }
`;

PathologyLiverResultsAccordion.propTypes = {
  eventKey: PropTypes.string.isRequired,
  isFormVisible: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
  valueSetter: PropTypes.func.isRequired,
};

export { PathologyLiverResultsAccordion };
