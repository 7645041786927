import { memo, useMemo, useState } from 'react';

import { faDash, faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button, ButtonVariants, ComponentSizes, ErrorMessage, Icon, PrimaryCard } from 'core/ui';

import { FileContext } from 'features/file/types/FileContext';

import { useUploadExamsPageContext } from '../hooks';
import { WizardStepKey } from '../types';
import { AddAttachmentFilesCard } from './AddAttachmentFilesCard';
import { AttachmentItem } from './AttachmentItem';
import { UploadGroupListItem } from './UploadGroupListItem';

export const AttachmentsStep = memo(() => {
  const { uploadGroups, uploadView, beginUpload, onNextStep, onPreviousStep, validateStep } = useUploadExamsPageContext();

  const [stepError, setStepError] = useState<string | null>(null);
  const [selectedUploadGroupId, setSelectedUploadGroupId] = useState(() => {
    const firstCheckedGroupIndex = uploadGroups.findIndex((group) => group.checked);
    return firstCheckedGroupIndex >= 0 ? uploadGroups[firstCheckedGroupIndex].uploadGroupId : null;
  });

  const selectedUploadGroup = useMemo(() => uploadGroups.find((u) => u.uploadGroupId === selectedUploadGroupId) ?? null, [selectedUploadGroupId, uploadGroups]);

  const handleUploadGroupClick = useEvent((uploadGroupId: string) => {
    setSelectedUploadGroupId(uploadGroupId);
  });

  const handleAddAttachmentFiles = useEvent((newFiles: File[]) => {
    if (selectedUploadGroupId == null) throw new Error('selectedUploadGroupId cannot be null or undefined.');
    if (selectedUploadGroup == null) throw new Error('selectedUploadGroup cannot be null or undefined.');

    const newFileContexts: FileContext[] = newFiles.map((file) => ({
      fileId: window.crypto.randomUUID(),
      file,
      dicomData: null,
    }));

    uploadView.updateGroup(selectedUploadGroupId, {
      attachments: [...newFileContexts, ...selectedUploadGroup.attachments],
    });
  });

  const handleRemoveAttachmentFiles = useEvent((fileId: string) => {
    if (selectedUploadGroupId == null) throw new Error('selectedUploadGroupId cannot be null or undefined.');
    if (selectedUploadGroup == null) throw new Error('selectedUploadGroup cannot be null or undefined.');

    uploadView.updateGroup(selectedUploadGroupId, {
      attachments: selectedUploadGroup.attachments.filter((a) => a.fileId !== fileId),
    });
  });

  const handleNextClick = useEvent(() => {
    if (validateStep(uploadGroups, WizardStepKey.Attachments)) {
      beginUpload();
      setStepError(null);
      onNextStep();
    } else {
      setStepError('Please resolve issues before proceeding.');
    }
  });

  return (
    <StyledComponentDiv>
      <StyledExamsListDiv>
        <StyledExamsListHeaderDiv>
          <StyledExamsListHeaderCellDiv>Exam</StyledExamsListHeaderCellDiv>
        </StyledExamsListHeaderDiv>
        <StyledExamsListScrollDiv>
          {uploadGroups
            .filter((group) => group.checked)
            .map((group) => (
              <UploadGroupListItem
                key={group.uploadGroupId}
                group={group}
                selected={group.uploadGroupId === selectedUploadGroupId}
                onClick={handleUploadGroupClick}
              />
            ))}
        </StyledExamsListScrollDiv>
      </StyledExamsListDiv>
      <StyledRightPanelDiv>
        {selectedUploadGroup != null && (
          <PrimaryCard>
            <PrimaryCard.Header>
              <Icon icon={faPaperclip} fixedWidth={false} block />
              <PrimaryCard.HeaderText>Attachments</PrimaryCard.HeaderText>
              <StyledSubHeaderText>
                <Icon icon={faDash} block fixedWidth={false} size="sm" />
                Upload additional files to selected study
              </StyledSubHeaderText>
            </PrimaryCard.Header>
            <PrimaryCard.Body $noPadding>
              <AddAttachmentFilesCard onAddAttachmentFiles={handleAddAttachmentFiles} />
              {selectedUploadGroup.attachments.length > 0 && (
                <StyledFilesContainerDiv>
                  {selectedUploadGroup.attachments.map((attachment) => (
                    <AttachmentItem key={attachment.fileId} attachment={attachment} onRemoveAttachmentFile={handleRemoveAttachmentFiles} />
                  ))}
                </StyledFilesContainerDiv>
              )}
            </PrimaryCard.Body>
          </PrimaryCard>
        )}
      </StyledRightPanelDiv>

      <StyledFooter>
        <StyledBigButton size={ComponentSizes.LARGE} onClick={onPreviousStep} variant={ButtonVariants.SECONDARY}>
          Back
        </StyledBigButton>
        <StyledNextButtonContainer>
          {stepError != null && <ErrorMessage>{stepError}</ErrorMessage>}
          <StyledBigButton size={ComponentSizes.LARGE} onClick={handleNextClick}>
            Next
          </StyledBigButton>
        </StyledNextButtonContainer>
      </StyledFooter>
    </StyledComponentDiv>
  );
});

AttachmentsStep.displayName = 'AttachmentsStep';

const StyledComponentDiv = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 400px 1fr;
  grid-template-rows: 1fr min-content;
  column-gap: ${({ theme }) => theme.space.spacing20};
  padding-right: ${({ theme }) => theme.space.spacing40};
`;

const StyledExamsListDiv = styled.div`
  grid-row: 1 / span 2;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 32px;
  user-select: none;
`;

const StyledExamsListHeaderDiv = styled.div`
  grid-column: 1 / span 2;
  display: grid;
  overflow: hidden;
  grid-template-columns: subgrid;
  font-size: ${({ theme }) => theme.fontSizes.subheading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.lineHeights.subheading};
  color: ${({ theme }) => theme.colors.palette.white};
  background-color: ${({ theme }) => theme.colors.primary};
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const StyledExamsListHeaderCellDiv = styled.div`
  display: grid;
  overflow: hidden;
  align-items: center;
  padding: 0 ${({ theme }) => theme.space.spacing40};
`;

const StyledExamsListScrollDiv = styled.div`
  grid-column: 1 / span 2;
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
  grid-template-columns: subgrid;
  grid-auto-rows: min-content;
`;

const StyledRightPanelDiv = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

const StyledSubHeaderText = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: ${({ theme }) => theme.lineHeights.subheading};
  color: ${({ theme }) => theme.colors.textPrimary};
  opacity: 0.9;
  user-select: none;

  .icon-container {
    align-self: baseline;
    margin: 0 ${({ theme }) => theme.space.spacing20};
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const StyledFilesContainerDiv = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(auto-fill, 120px);
  grid-auto-rows: 140px;
  margin-top: 12px;
  gap: 6px;
`;

const StyledBigButton = styled(Button)`
  && {
    display: flex;
    width: initial;
    height: initial;
    padding: 5px 16px;
    line-height: 22px;
  }

  .k-button-text {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: 22px;
  }
`;

const StyledFooter = styled.div`
  align-self: end;
  grid-column: 1 / span 2;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.spacing40} 0;
`;

const StyledNextButtonContainer = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;

  button {
    margin-left: ${({ theme }) => theme.space.spacing40};
  }
`;
