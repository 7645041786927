import dayjs from 'dayjs';

import { PatientAgeRangeModel } from 'models/PatientAgeRangeModel';

import { hasText } from 'core/utils';

function calculateAgeFromDob(dob: Date | string | null | undefined): string {
  if (dob == null) return '';

  const parsed = dayjs(dob);
  if (!parsed.isValid()) return '';
  const age = dayjs().diff(parsed, 'year');
  return isNaN(age) ? '' : age.toString();
}

function formatName(firstName: string | null | undefined, lastName: string | null | undefined): string | null {
  if (firstName == null && lastName == null) {
    return null;
  } else if (hasText(firstName) && hasText(lastName)) {
    return `${firstName.trim()} ${lastName.trim()}`;
  } else {
    return (hasText(firstName) ? firstName.trim() : lastName?.trim()) ?? null;
  }
}

function formatDob(dob: Date | string | null | undefined): string {
  const parsedDob = dayjs(dob);
  return parsedDob.isValid() ? parsedDob.format('MM/DD/YYYY') : '';
}

function formatCrossClampDateTime(crossClampDateTime: string | null): string {
  const parsed = dayjs(crossClampDateTime);
  return parsed.isValid() ? parsed.format('MM/DD/YYYY hh:mm:ss') : '';
}

// this function calculate the age range
// based on the patient's age.
function calculateAgeRange(ageRanges: PatientAgeRangeModel[] | undefined, ageParam: string | null): PatientAgeRangeModel | null | undefined {
  if (!ageRanges || !ageParam) return null;
  // check type
  if (typeof ageParam === 'string') {
    // parse the age
    const age = Number.parseInt(ageParam, 10);
    let result = null;
    ageRanges.forEach((item) => {
      if (item.endRange != null && item.startRange != null && age <= item.endRange && age >= item.startRange) {
        result = item;
      }
    });
    return result;
  }
  return null;
}

export const PatientUtils = {
  calculateAgeFromDob,
  formatName,
  formatDob,
  formatCrossClampDateTime,
  calculateAgeRange,
};
