import { FunctionComponent, useCallback, useState } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { DestinationModel, QueryModel } from 'models';
import { DCMStudyInstanceModel } from 'models/DCMStudyInstanceModel';

import { FieldContainer, createStyledRhfForm } from 'core/forms';
import { useEvent } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, Card, DEFAULT_PAGE_SIZES, DataTable, DropdownField, GridColumn, HeaderCell, InputField, Page, PageHeader, TextCell, styled } from 'core/ui';
import { hasText } from 'core/utils';

import { apiClient } from 'features/api';

const StyledForm = createStyledRhfForm('');

export const QueryRetrieveHome: FunctionComponent = () => {
  const [pacsData, setPACSData] = useState<DCMStudyInstanceModel[]>([]);
  const [pacsDataTotal, setPACSDataTotal] = useState(0);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 40,
  });
  const [selectedExams, setSelectedExams] = useState<Record<string, boolean>>({});
  const [destinationId, setDestinationId] = useState<number>(0);
  const [importing, setImporting] = useState<boolean>(false);

  const hasSelectedExams = Object.keys(selectedExams).filter((key) => selectedExams[key]).length > 0;
  const rhfContext = useForm({});
  const {
    reset,
    formState: { isSubmitting },
  } = rhfContext;

  const handleSubmit: SubmitHandler<QueryModel> = useCallback(
    async (values: QueryModel, event) => {
      event?.preventDefault();
      console.log('submit', values, event);

      //TODO: fix destinationId mapping
      if (hasText(values.patientName) && !values.patientName.includes('*')) {
        values.patientName = values.patientName + '*'; //add wildcard search
      }
      //values.destinationId = values.destinationId?.id;
      //setDestinationId(values.destinationId);

      const queryResult = await apiClient.dicomClient.query(values);
      console.log('submit results', queryResult);

      //setPACSData(queryResult.results);
      //setPACSDataTotal(queryResult.results.length);
    },
    [reset],
  );

  //TODO: pull from list of allowed archives
  const remoteArchives = [{ name: 'Test Archive', id: 1013 }];

  const importExams = useEvent(async () => {
    const importExams = Object.keys(selectedExams).filter((key) => selectedExams[key]);
    NotificationsService.displaySuccess(`Importing Exams`);
    setImporting(true);
    const importResult = await apiClient.dicomClient.import(destinationId, importExams);
    NotificationsService.displaySuccess(`Exams Imported Successfully`);
    setImporting(false);
    setSelectedExams({});
    //navigate back
  });

  return (
    <Page>
      <PageHeader title="Query Retrieve" />
      <Card>
        <FormProvider {...rhfContext}>
          <StyledForm autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" noValidate onSubmit={rhfContext.handleSubmit(handleSubmit)}>
            <StyledFormContainer>
              <FieldContainer>
                <InputField name="patientName" label="Patient Name" />
              </FieldContainer>
              <FieldContainer>
                <InputField name="patientID" label="Patient MRN" />
              </FieldContainer>
              <FieldContainer>
                <InputField name="DOB" label="Patient DOB" />
              </FieldContainer>
              <FieldContainer>
                <InputField name="accession" label="Accession" />
              </FieldContainer>
              <FieldContainer>
                <InputField name="studyDescription" label="Description" />
              </FieldContainer>
              <FieldContainer>
                <InputField name="studyDate" label="StudyDate" />
              </FieldContainer>

              <FieldContainer>
                <DropdownField name="destinationId" label="Remote Archive" filterable={false} data={remoteArchives} />
              </FieldContainer>
            </StyledFormContainer>
            <FieldContainer>
              <Button disabled={isSubmitting} type="submit">
                Search
              </Button>
            </FieldContainer>
          </StyledForm>
        </FormProvider>

        <StyledDataTable
          data={pacsData}
          //filterable
          total={pacsDataTotal}
          selectable
          selectedState={selectedExams}
          onSelectionChange={setSelectedExams}
          dataItemKey={'suid'}
          {...dataState}
        >
          <GridColumn title="Accession" field="accession" filter="text" headerCell={HeaderCell} cell={TextCell} width={80} />
          <GridColumn title="MRN" field="mrn" filter="text" headerCell={HeaderCell} cell={TextCell} />
          <GridColumn title="SUID" field="suid" filter="text" headerCell={HeaderCell} cell={TextCell} />
          <GridColumn title="Study Date" field="studyDate" filter="text" headerCell={HeaderCell} cell={TextCell} />
          <GridColumn title="Birth Date" field="birthDate" filter="text" headerCell={HeaderCell} cell={TextCell} />
          <GridColumn title="Patient Name" field="patientName" filter="text" headerCell={HeaderCell} cell={TextCell} />
          <GridColumn title="Study Description" field="studyDescription" filter="text" headerCell={HeaderCell} cell={TextCell} />
        </StyledDataTable>
        <br />
        <FieldContainer>
          <Button disabled={!hasSelectedExams || importing} type="submit" onClick={importExams}>
            Import Selected Exams
          </Button>
        </FieldContainer>
      </Card>
    </Page>
  );
};

QueryRetrieveHome.displayName = 'Query Retrieve';
const StyledDataTable = styled(DataTable)`
  max-height: 50vh;
  overflow: auto;
`;

const StyledFormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  max-width: 80vw;
`;
