import { forwardRef } from 'react';

import { Switch as KendoSwitch, SwitchHandle } from '@progress/kendo-react-inputs';
import styled, { DefaultTheme } from 'styled-components';

import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Label } from '../Label';
import { SwitchProps } from './SwitchProps';

export const Switch = forwardRef<SwitchHandle, SwitchProps>(
  (
    {
      isOptionalLabelShown = false,
      isInnerLabelVisible = false,
      disabled,
      name,
      required,
      valid,
      validationMessage,
      value,
      label,
      visited,
      hint,
      description,
      onChange,
      onFocus,
      onBlur,
      ...rest
    },
    ref,
  ) => {
    const isValidationMessageShown = Boolean(visited && validationMessage);
    const isHintShown = Boolean(!isValidationMessageShown && hint);
    const hintId = isHintShown ? `${name}_hint` : '';
    const errorId = isValidationMessageShown ? `${name}_error` : '';
    const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);

    return (
      <>
        <>
          <Label description={description} editorId={name} editorValid={valid} editorDisabled={disabled} required={required} optional={isLabeledAsOptional}>
            {label}
          </Label>

          <StyledSwitchBase
            ref={ref}
            {...rest}
            checked={Boolean(value)}
            disabled={disabled}
            name={name}
            $isInnerLabelVisible={isInnerLabelVisible}
            valid={valid}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            size="small"
          />
        </>

        {isHintShown && <Hint id={hintId}>{hint}</Hint>}
        {isValidationMessageShown && <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>}
      </>
    );
  },
);

Switch.displayName = 'Switch';

type StyledElementProps = {
  theme: DefaultTheme;
  checked?: boolean;
  $isInnerLabelVisible?: boolean;
  disabled?: boolean;
};

const resolvePalette = ({ theme, checked }: StyledElementProps) => ({
  text: theme.colors.palette.white,
  background: checked ? theme.colors.primary : theme.colors.palette.grayscale[5],
  handleBackground: theme.colors.palette.white,
});

const resolveColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveHandleBackgroundColor = (props: StyledElementProps) => {
  const { handleBackground } = resolvePalette(props);

  return handleBackground;
};

const resolveInnerLabelColor = (props: StyledElementProps) => {
  const { $isInnerLabelVisible } = props;

  return $isInnerLabelVisible ? resolveColor(props) : 'transparent';
};

const resolveOnLabelVisibility = ({ checked }: StyledElementProps) => (checked ? 'visible' : 'hidden');

const resolveOffLabelVisibility = ({ checked }: StyledElementProps) => (checked ? 'hidden' : 'visible');

const resolveOpacity = ({ disabled }: StyledElementProps) => (disabled ? 0.4 : 1);

const StyledSwitchBase = styled(KendoSwitch)<StyledElementProps>`
  && .k-switch-track {
    background-color: ${resolveBackgroundColor};
    border-color: transparent;
    opacity: ${resolveOpacity};
    height: 20px;
  }

  && .k-switch-thumb {
    background-color: ${resolveHandleBackgroundColor};
    filter: drop-shadow(0px 2px 4px rgba(0, 35, 11, 0.2));
    height: 18px;
    width: 18px;
  }

  &&:focus,
  &&.k-focus {
    box-shadow: none;
  }

  & .k-switch-label-on,
  & .k-switch-label-off {
    color: ${resolveInnerLabelColor};
    font-size: ${({ theme }) => theme.fontSizes.footnote};
    line-height: ${({ theme }) => theme.lineHeights.footnote};
  }

  & .k-switch-label-on {
    left: ${({ theme }) => theme.space.switchLabelMargin};
    visibility: ${resolveOnLabelVisibility};
  }

  & .k-switch-label-off {
    right: ${({ theme }) => theme.space.switchLabelMargin};
    visibility: ${resolveOffLabelVisibility};
  }

  &&.k-switch-sm {
    height: 20px;
  }
`;
