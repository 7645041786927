import { ComponentPropsWithRef, FunctionComponent } from 'react';

import { Button as KendoButton, ButtonProps as KendoButtonProps } from '@progress/kendo-react-buttons';
import styled, { DefaultTheme } from 'styled-components';

import { ComponentSizes } from '../constants';
import { LinkButtonProps } from './LinkButtonProps';

export const LinkButton: FunctionComponent<LinkButtonProps> = ({ children, className, disabled, form, icon, onClick, size = ComponentSizes.MEDIUM, type }) => (
  <StyledButtonLink className={className} disabled={disabled} form={form} icon={icon} onClick={onClick} size={size} type={type}>
    {children}
  </StyledButtonLink>
);

LinkButton.displayName = 'LinkButton';

type StyledElementProps = {
  theme: DefaultTheme;
  size?: KendoButtonProps['size'];
  disabled?: boolean;
};

const resolveFontSize = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.body;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.body;
  }
};

const resolveInnerSpanFontSize = ({ theme, size }: StyledElementProps) => resolveFontSize({ theme, size });

const resolveLineHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.body;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.body;
  }
};

const resolveInnerSpanLineHeight = ({ theme, size }: StyledElementProps) => resolveLineHeight({ theme, size });

const resolvePalette = ({ theme, disabled }: StyledElementProps) => {
  if (disabled) {
    return {
      text: theme.colors.textDisabled,
      background: theme.colors.palette.white,
    };
  }

  return {
    text: theme.colors.secondary,
    textActive: theme.colors.palette.aquas[4],
    background: theme.colors.palette.white,
  };
};

const resolveColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolveActiveColor = (props: StyledElementProps) => {
  const { textActive } = resolvePalette(props);

  return textActive;
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.SMALL:
      return theme.sizes.medium;
    default:
      throw new Error(`Could not resolve height for size "${size}".`);
  }
};

const StyledButtonLink: FunctionComponent<ComponentPropsWithRef<typeof KendoButton>> = styled(KendoButton)`
  && {
    height: ${resolveHeight};
  }
  border: none;
  color: ${resolveColor};
  background-color: ${resolveBackgroundColor};
  padding: 0;
  font-size: ${resolveFontSize};
  line-height: ${resolveLineHeight};

  &:hover,
  &:focus {
    color: ${resolveActiveColor};
    background-color: ${resolveBackgroundColor};
    box-shadow: none;
  }

  & span {
    font-size: ${resolveInnerSpanFontSize};
    line-height: ${resolveInnerSpanLineHeight};
  }

  &:disabled {
    opacity: 1;
    filter: none;
  }
`;
