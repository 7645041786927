import React from 'react';

import { LogsInitConfiguration, StatusType, datadogLogs } from '@datadog/browser-logs';
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';
import { createRoot } from 'react-dom/client';

import { equalsInsensitive, hasText } from 'core/utils';

import { AppProvider } from './fragments';

// dayjs plugins should be globally installed here.
dayjs.extend(duration);
dayjs.extend(dayjsUtc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const container = document.getElementById('root')!;
const root = createRoot(container);

/** Note: For development environments the sample rate for Datadog should be set to `false` to avoid blowing up our Datadog bill.  If you need to enable Datadog for development then set the VITE_APP_DEVELOPMENT_DATADOG_ENABLED environment variable to either `true` or `false`. This can be done by creating a `.env.development.local` file in the root of the Vite app directory and adding the following line:
 * @example
 *  VITE_APP_DEVELOPMENT_DATADOG_ENABLED = true
 * @defaultValue 0
 */
const DEVELOPMENT_DATADOG_SAMPLE_RATE = equalsInsensitive(import.meta.env.VITE_APP_DEVELOPMENT_DATADOG_ENABLED, 'true') ? 100 : 0;

const minLogLevel: StatusType = equalsInsensitive(import.meta.env.VITE_APP_DATADOG_MIN_LOG_LEVEL, 'debug')
  ? StatusType.debug
  : equalsInsensitive(import.meta.env.VITE_APP_DATADOG_MIN_LOG_LEVEL, 'info')
    ? StatusType.info
    : equalsInsensitive(import.meta.env.VITE_APP_DATADOG_MIN_LOG_LEVEL, 'warn')
      ? StatusType.warn
      : equalsInsensitive(import.meta.env.VITE_APP_DATADOG_MIN_LOG_LEVEL, 'error')
        ? StatusType.error
        : StatusType.info; // Fallback to "info" as the default log level.

const datadogRumConfig: RumInitConfiguration = {
  applicationId: '3b0aaa4a-548a-4bed-ab7f-a87e14425f04',
  clientToken: import.meta.env.VITE_APP_DATADOG_CLIENT_TOKEN,
  site: 'us3.datadoghq.com',
  env: import.meta.env.MODE,
  service: 'portal-frontend',
  version: hasText(import.meta.env.VITE_APP_VERSION) ? import.meta.env.VITE_APP_VERSION : '0.0.0',
  sessionSampleRate: import.meta.env.DEV ? DEVELOPMENT_DATADOG_SAMPLE_RATE : 100,
  traceSampleRate: import.meta.env.DEV ? DEVELOPMENT_DATADOG_SAMPLE_RATE : 100,
  sessionReplaySampleRate: import.meta.env.DEV ? DEVELOPMENT_DATADOG_SAMPLE_RATE : 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
  compressIntakeRequests: true,
  storeContextsAcrossPages: true,
  allowedTracingUrls: [
    import.meta.env.VITE_APP_COMPUMED_API_BASE_URL,
    import.meta.env.VITE_APP_COMPUMED_PORTAL_BASE_URL,
    import.meta.env.VITE_APP_COMPUMED_SSO_BASE_URL,
    /https:\/\/.*\.blob\.core\.windows\.net/, // https://*.blob.core.windows.net
    ...(hasText(import.meta.env.VITE_APP_BLOB_STORAGE_ALTERNATE_URL) ? [import.meta.env.VITE_APP_BLOB_STORAGE_ALTERNATE_URL] : []),
  ],
};

const datadogLogsConfig: LogsInitConfiguration = {
  clientToken: import.meta.env.VITE_APP_DATADOG_CLIENT_TOKEN,
  site: 'us3.datadoghq.com',
  env: import.meta.env.MODE,
  service: 'portal-frontend',
  version: hasText(import.meta.env.VITE_APP_VERSION) ? import.meta.env.VITE_APP_VERSION : '0.0.0',
  forwardErrorsToLogs: true,
  storeContextsAcrossPages: true,
  forwardReports: 'all',
  sessionSampleRate: import.meta.env.DEV ? DEVELOPMENT_DATADOG_SAMPLE_RATE : 100,
};

datadogRum.init(datadogRumConfig);
datadogLogs.init(datadogLogsConfig);
datadogLogs.logger.setLevel(minLogLevel);

// Add unique tags for each developer so that we can filter observability data by individual developers in Datadog.  This only makes sense in a development environment.
if (typeof import.meta.env.VITE_APP_DEVELOPER === 'string') {
  datadogRum.setGlobalContextProperty('developer', import.meta.env.VITE_APP_DEVELOPER);
  datadogLogs.setGlobalContextProperty('developer', import.meta.env.VITE_APP_DEVELOPER);
}

if (DEVELOPMENT_DATADOG_SAMPLE_RATE > 0) {
  console.warn(`Datadog RUM and Logs are enabled.`, {
    datadogRumConfig,
    datadogLogsConfig,
    environment: import.meta.env,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    DD_RUM: (window as any).DD_RUM,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    DD_LOGS: (window as any).DD_LOGS,
    minLogLevel,
  });
}

// Increase the resource timing buffer size to 10,000 entries.  This is required because we do a ton of performance measuring for DICOM parsing, thumbnail generation, and uploads.
performance.setResourceTimingBufferSize(10000);

root.render(
  <React.StrictMode>
    <AppProvider />
  </React.StrictMode>,
);
