import { ComponentPropsWithRef, FunctionComponent } from 'react';

import { Button as KendoButton, ButtonProps as KendoButtonProps } from '@progress/kendo-react-buttons';
import styled, { DefaultTheme } from 'styled-components';

import { ComponentSizes } from '../constants';
import { TextButtonProps } from './TextButtonProps';

export const TextButton: FunctionComponent<TextButtonProps> = ({ type = 'button', size = ComponentSizes.MEDIUM, children, ...rest }) => (
  <StyledButtonText type={type} size={size} {...rest}>
    {children}
  </StyledButtonText>
);

TextButton.displayName = 'TextButton';

type StyledElementProps = {
  theme: DefaultTheme;
  size?: KendoButtonProps['size'];
  disabled?: boolean;
};

const resolveFontSize = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.body;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.body;
  }
};

const resolveInnerSpanFontSize = ({ theme, size }: StyledElementProps) => resolveFontSize({ theme, size });

const resolveLineHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.body;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.body;
  }
};

const resolveInnerSpanLineHeight = ({ theme, size }: StyledElementProps) => resolveLineHeight({ theme, size });

const resolvePadding = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return `0rem ${theme.space.spacing20}`;
    case ComponentSizes.MEDIUM:
      return `${theme.space.spacing10} ${theme.space.spacing40}`;
    case ComponentSizes.LARGE:
      return `${theme.space.spacing20} ${theme.space.spacing40}`;
    default:
      return `${theme.space.spacing10} ${theme.space.spacing40}`;
  }
};

const resolvePalette = ({ theme, disabled }: StyledElementProps) => {
  if (disabled) {
    return {
      text: theme.colors.textDisabled,
      background: theme.colors.palette.white,
    };
  }

  return {
    text: theme.colors.textPrimary,
    backgroundActive: theme.colors.palette.grayscale[2],
    background: theme.colors.palette.white,
  };
};

const resolveColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveActiveBackgroundColor = (props: StyledElementProps) => {
  const { backgroundActive } = resolvePalette(props);

  return backgroundActive;
};

const resolveHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.SMALL:
      return theme.sizes.medium;
    default:
      throw new Error(`Could not resolve height for size "${size}".`);
  }
};

const StyledButtonText: FunctionComponent<ComponentPropsWithRef<typeof KendoButton>> = styled(KendoButton)`
  && {
    height: ${resolveHeight};
  }
  border: none;
  color: ${resolveColor};
  background-color: ${resolveBackgroundColor};
  font-size: ${resolveFontSize};
  line-height: ${resolveLineHeight};
  padding: ${resolvePadding};

  &:hover,
  &:focus {
    background-color: ${resolveActiveBackgroundColor};
    box-shadow: none;
  }

  & span {
    font-size: ${resolveInnerSpanFontSize};
    line-height: ${resolveInnerSpanLineHeight};
  }

  &:disabled {
    opacity: 1;
    filter: none;
  }
`;
