import { memo, useEffect, useMemo, useState } from 'react';

import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import styled from 'styled-components';

import { ExamModel, PatientModel, ServiceModel } from 'models';

import { useDataStream, useEvent, useEventStream, useValidatedParam } from 'core/hooks';
import { Page, PageHeader } from 'core/ui';

import { apiClient } from 'features/api';
import { UploadPipelineProvider, useUploadPipeline } from 'features/file';
import { useSessionLocation } from 'features/location';

import { INITIAL_WIZARD_STEPS } from '../constants';
import { UploadExamsPageContext } from '../contexts';
import { ExamUploadService, UploadView } from '../services';
import { UploadExamsPageContextType, UploadGroup, UploadStateType, WizardStepKey, WizardStepProps } from '../types';
import { AttachmentsStep } from './AttachmentsStep';
import { ExamsStep } from './ExamsStep';
import { ReviewAndUploadStep } from './ReviewAndUploadStep';
import { SelectFilesStep } from './SelectFilesStep';
import { WizardStep } from './WizardStep';

const UploadExamsPageInner = memo<{ uploadView: UploadView }>(({ uploadView }) => {
  const { uploadPipeline } = useUploadPipeline();
  const { sessionLocation } = useSessionLocation(true);
  const patientIdParam = useValidatedParam('patientId', 'integer', false);
  const examIdParam = useValidatedParam('examId', 'integer', false);

  const [allServices, setAllServices] = useState<ServiceModel[] | null>(null);
  const [fixedPatient, setFixedPatient] = useState<PatientModel | null>(null);
  const [fixedExam, setFixedExam] = useState<ExamModel | null>(null);
  const uploadGroups = useDataStream(uploadView.streams.groups);
  const [uploadState, setUploadState] = useState(UploadStateType.SELECTION);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [wizardSteps, setWizardSteps] = useState<WizardStepProps[]>(() => {
    const initialSteps = INITIAL_WIZARD_STEPS.map((step, index) => ({
      ...step,
      disabled: currentStepIndex !== index,
    }));
    return initialSteps;
  });

  const currentStepKey = wizardSteps[currentStepIndex].stepKey;

  const isInitialized = allServices != null && (patientIdParam == null || fixedPatient != null) && (examIdParam == null || fixedExam != null);

  const initialize = useEvent(async () => {
    const results = await Promise.all([
      apiClient.servicesClient.getAllServices(),
      patientIdParam != null ? apiClient.patientClient.getPatient(patientIdParam) : new Promise<null>((resolve) => resolve(null)),
      examIdParam != null ? apiClient.exams.getExamById(examIdParam) : new Promise<null>((resolve) => resolve(null)),
    ]);

    uploadPipeline.setFixedEntities(results[1], results[2], sessionLocation.id);
    uploadView.initialize(sessionLocation.id, results[1], results[2], results[0]);

    setAllServices(results[0]);
    setFixedPatient(results[1] ?? null);
    setFixedExam(results[2] ?? null);
  });

  const validateStep = useEvent((uploadGroups: UploadGroup[], stepKey: WizardStepKey) => {
    const newWizardSteps = wizardSteps.map((step) => {
      if (stepKey !== WizardStepKey.ReviewAndUpload && step.stepKey !== stepKey) return step;

      if (step.stepKey === WizardStepKey.SelectFiles) {
        const checkedCount = uploadGroups.filter((item) => item.checked).length;

        return {
          ...step,
          isValid: checkedCount > 0,
        };
      } else if (step.stepKey === WizardStepKey.Exams) {
        let checkedCount = 0,
          validCount = 0,
          validatingCount = 0,
          dirtyCount = 0,
          selectedPatientCount = 0,
          selectedExamCount = 0;

        for (const uploadGroup of uploadGroups) {
          checkedCount += uploadGroup.checked ? 1 : 0;
          validCount += uploadGroup.patientFormState.isValid ? 1 : 0;
          validatingCount += uploadGroup.patientFormState.isValidating ? 1 : 0;
          dirtyCount += uploadGroup.patientFormState.isDirty ? 1 : 0;
          selectedPatientCount += uploadGroup.patient != null ? 1 : 0;
          selectedExamCount += uploadGroup.exam != null ? 1 : 0;
        }

        return {
          ...step,
          isValid:
            validCount === checkedCount &&
            validatingCount === 0 &&
            dirtyCount === 0 &&
            selectedPatientCount === checkedCount &&
            selectedExamCount === checkedCount,
        };
      } else if (step.stepKey === WizardStepKey.Attachments) {
        return {
          ...step,
        };
      } else if (step.stepKey === WizardStepKey.ReviewAndUpload) {
        return {
          ...step,
        };
      }

      return step;
    });

    const reviewStepIndex = newWizardSteps.findIndex((step) => step.stepKey === WizardStepKey.ReviewAndUpload);
    const validStepsCount = newWizardSteps.filter((step) => step.isValid && step.stepKey !== WizardStepKey.ReviewAndUpload).length;

    newWizardSteps[reviewStepIndex] = {
      ...newWizardSteps[reviewStepIndex],
      isValid: validStepsCount === newWizardSteps.length - 1, // Offset by 1 because the review step is not included in the count.
    };

    setWizardSteps(newWizardSteps);

    return newWizardSteps.find((step) => step.stepKey === stepKey)?.isValid ?? false;
  });

  const beginUpload = useEvent(() => {
    uploadPipeline.uploadFiles(
      uploadGroups
        .filter((g) => g.checked)
        .flatMap((g) => [...g.files, ...g.attachments])
        .map((f) => f.fileId),
    );

    setUploadState(UploadStateType.UPLOADING);
  });

  const setStep = useEvent((target: WizardStepKey | 'next' | 'previous') => {
    let newStepIndex: number;

    if (target === 'next' || target === 'previous') {
      newStepIndex = currentStepIndex + (target === 'next' ? 1 : -1);

      if (newStepIndex >= wizardSteps.length || newStepIndex < 0) throw new Error('Cannot navigate back or forward from the current step.');
    } else {
      newStepIndex = wizardSteps.findIndex((step) => step.stepKey === target);
    }

    setWizardSteps((prev) => {
      const newWizardSteps = prev.map((step, index) => ({
        ...step,
        visited: step.visited || index === currentStepIndex,
        disabled: !(step.visited || index <= newStepIndex || index === currentStepIndex),
      }));

      return newWizardSteps;
    });
    setCurrentStepIndex(newStepIndex);
  });

  const handleNextStep = useEvent(() => setStep('next'));

  const handlePreviousStep = useEvent(() => setStep('previous'));

  const handleStepChange = useEvent((event: StepperChangeEvent) => {
    setStep(wizardSteps[event.value].stepKey);
  });

  /*useEventStream(uploadView.streams.groups, (groups) => {
    if (allServices == null) throw new Error('Cannot proceed because allServices is null.');

    // We need to navigate into the stream to get the previous value because React batches useState updates and we need to ensure that we are working with the most recent state.
    const prev = uploadView.streams.groups.getCurrentValue();
    const newUploadGroups: UploadGroup[] = [];

    for (const group of groups) {
      const existingUploadGroup = prev.find((item) => item.uploadGroupId === group.uploadGroupId);

      if (existingUploadGroup == null) {
        newUploadGroups.push({
          ...group,
          checked: true,
          patient: fixedPatient,
          exam: fixedExam,
          patientForm: fixedPatient ? ExamUploadService.patientModelToForm(fixedPatient) : ExamUploadService.patientDicomToForm(group.dicomData),
          examForm: fixedExam ? ExamUploadService.examModelToForm(fixedExam, allServices) : ExamUploadService.examDicomToForm(group.dicomData, allServices),
          patientFormState: { isDirty: false, isValid: fixedPatient ? true : false, isValidating: false },
          examFormState: { isDirty: false, isValid: fixedExam ? true : false, isValidating: false },
        });
      } else {
        newUploadGroups.push({
          ...existingUploadGroup,
          ...group,
        });
      }
    }

    setUploadGroups(newUploadGroups);
  });*/

  useEffect(() => {
    initialize();
  }, [initialize, uploadPipeline, uploadView]);

  const uploadContext: UploadExamsPageContextType = useMemo(
    () => ({
      uploadState,
      allServices: allServices!, // Non-null assertion is safe because we are not rendering any consumers of the context until AFTER we have fetched the services.
      fixedPatient,
      fixedExam,
      uploadGroups,
      uploadView,
      setUploadState,
      setStep,
      validateStep,
      beginUpload,
      onNextStep: handleNextStep,
      onPreviousStep: handlePreviousStep,
    }),
    [uploadState, allServices, fixedPatient, fixedExam, uploadGroups, uploadView, setStep, validateStep, beginUpload, handleNextStep, handlePreviousStep],
  );

  if (!isInitialized) return null;

  return (
    <UploadExamsPageContext.Provider value={uploadContext}>
      <Page>
        <PageHeader title="Upload Exams" showSessionLocation />
        <StyledOutletContainer>
          <StyledStepperContainer>
            <Stepper
              items={wizardSteps}
              value={currentStepIndex}
              onChange={handleStepChange}
              item={WizardStep}
              disabled={uploadState !== UploadStateType.SELECTION}
            />
          </StyledStepperContainer>
          {currentStepKey === WizardStepKey.SelectFiles ? (
            <SelectFilesStep />
          ) : currentStepKey === WizardStepKey.Exams ? (
            <ExamsStep />
          ) : currentStepKey === WizardStepKey.Attachments ? (
            <AttachmentsStep />
          ) : currentStepKey === WizardStepKey.ReviewAndUpload ? (
            <ReviewAndUploadStep />
          ) : null}
        </StyledOutletContainer>
      </Page>
    </UploadExamsPageContext.Provider>
  );
});

UploadExamsPageInner.displayName = 'UploadExamsPageInner';

const UploadExamsPageDependencies = memo(() => {
  const { uploadPipeline } = useUploadPipeline();

  const [uploadView, setUploadView] = useState<UploadView | null>(null);

  useEffect(() => {
    const newUploadView = new UploadView(uploadPipeline);
    setUploadView(newUploadView);

    return () => {
      newUploadView.destroy();
    };
  }, [uploadPipeline]);

  if (uploadView == null) return null;

  return <UploadExamsPageInner uploadView={uploadView} />;
});

UploadExamsPageDependencies.displayName = 'UploadExamsPageDependencies';

export const UploadExamsPage = memo(() => {
  return (
    <UploadPipelineProvider>
      <UploadExamsPageDependencies />
    </UploadPipelineProvider>
  );
});

UploadExamsPage.displayName = 'UploadExamsPage';

const StyledOutletContainer = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;

  .k-stepper {
    user-select: none;
  }

  .k-step-link {
    cursor: pointer;
  }

  .k-step-list-horizontal ~ .k-progressbar {
    top: calc((54px + 2 * 2px) / 2 + 2px / 2);
  }

  .k-step-list-vertical ~ .k-progressbar {
    left: calc((50px + 2 * 1px + 2 * 2px) / 2);
  }
`;

const StyledStepperContainer = styled.div`
  padding: ${({ theme }) => theme.space.spacing40} 0;

  .k-progressbar {
    --kendo-color-primary: ${({ theme }) => theme.colors.primary};
  }
`;
