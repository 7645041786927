import { FunctionComponent, useMemo } from 'react';

import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import styled from 'styled-components';

import { PatientAgeRangeModel } from 'models/PatientAgeRangeModel';

import { Field, FormValidatorsService, required } from 'core/forms';
import { Checkbox, DatePicker, Dropdown, DropdownWithValuesField, Input, NumericInput, Switch, TextArea } from 'core/ui';

import { PatientGenders } from '../constants';
import { PatientUtils } from '../services';
import { PatientFormValues } from '../types';

export const PatientFormFields: FunctionComponent<{
  isFormDisabled: boolean;
  displayValues: PatientFormValues;
  handleFormChange: (data: any) => void;
  isEditMode: boolean;
  ageRanges: PatientAgeRangeModel[] | undefined;
  age: string;
  isSubProperty: boolean;
  valueSetter: (key: string, value: any) => void;
  valueGetter: (key: string) => any;
  isOpo?: boolean;
}> = ({ isFormDisabled, isOpo, displayValues, handleFormChange, isEditMode, ageRanges, age, isSubProperty, valueGetter }) => {
  const isOpoLocation = isOpo || displayValues?.location?.locationType?.name === 'OPO' || displayValues?.locationType === 'OPO';
  const isUnosUnavailable = valueGetter('isUnosUnavailable');
  const caseId = valueGetter('caseID');
  const unosId = valueGetter('unosID');
  const ageRange = valueGetter('ageRange');

  const unosValidations = useMemo(() => {
    return [FormValidatorsService.required, FormValidatorsService.unos];
  }, []);

  return (
    <>
      <StyledFormDiv>
        {isOpoLocation && (
          <div>
            {isFormDisabled && (
              <>
                <StyledBoldText>Unos ID:</StyledBoldText>&nbsp;
                {displayValues.unosID}
              </>
            )}
            <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
              <Field
                component={Input}
                disabled={isFormDisabled}
                label="Unos ID"
                name={isSubProperty ? 'patient.unosID' : 'unosID'}
                required={isOpoLocation && !caseId}
                validator={!caseId ? unosValidations : undefined}
                onChange={handleFormChange}
              />
              <StyledUnosUnavailableField component={StyledCheckbox} label="UNOS ID Unavailable" name="isUnosUnavailable" onChange={handleFormChange} />
            </StyledFormFieldContainer>
          </div>
        )}
        <div>
          {isFormDisabled && (
            <>
              <StyledBoldText>Case ID:</StyledBoldText>&nbsp;
              {displayValues.caseID}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={Input}
              disabled={isFormDisabled}
              label="Case ID"
              name={isSubProperty ? 'patient.caseID' : 'caseID'}
              required={isOpoLocation && !unosId}
              validator={isOpoLocation && !unosId ? required : undefined}
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </div>
        <div>
          {isFormDisabled && (
            <>
              <StyledBoldText>First Name:</StyledBoldText>&nbsp;
              {displayValues.firstName}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={Input}
              disabled={isFormDisabled}
              label="First Name"
              name={isSubProperty ? 'patient.firstName' : 'firstName'}
              required
              validator={required}
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </div>
        <div>
          {isFormDisabled && (
            <>
              <StyledBoldText>Last Name:</StyledBoldText>&nbsp;
              {displayValues.lastName}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={Input}
              disabled={isFormDisabled}
              label="Last Name"
              name={isSubProperty ? 'patient.lastName' : 'lastName'}
              required
              validator={isOpoLocation ? required : undefined}
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </div>
        <div>
          {isFormDisabled && (
            <>
              <StyledBoldText>DOB:</StyledBoldText>&nbsp;
              {PatientUtils.formatDob(displayValues.dob)}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field component={DatePicker} disabled={isFormDisabled} label="DOB" name={isSubProperty ? 'patient.dob' : 'dob'} onChange={handleFormChange} />
          </StyledFormFieldContainer>
        </div>
        {isFormDisabled ? (
          <>
            <div>
              <StyledBoldText>Age Range:</StyledBoldText>&nbsp;
              {ageRange?.description}
            </div>
          </>
        ) : (
          <div>
            <Field
              component={Dropdown}
              data={ageRanges}
              disabled={isFormDisabled}
              label="Age Range"
              name={isSubProperty ? 'patient.ageRange' : 'ageRange'}
              textField="description"
              required
              validator={required}
              onChange={handleFormChange}
            />
          </div>
        )}
        <div>
          {isFormDisabled && (
            <>
              <StyledBoldText>Weight:</StyledBoldText>&nbsp;
              {displayValues.weight}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={NumericInput}
              disabled={isFormDisabled}
              label="Weight (kg)"
              name={isSubProperty ? 'patient.weight' : 'weight'}
              min="0"
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </div>
        <div>
          {isFormDisabled && (
            <>
              <StyledBoldText>MRN:</StyledBoldText>&nbsp;
              {displayValues.patientNumber}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={Input}
              disabled={isFormDisabled}
              label="MRN"
              name={isSubProperty ? 'patient.patientNumber' : 'patientNumber'}
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </div>
        <div>
          {isFormDisabled && (
            <>
              <StyledBoldText>Height:</StyledBoldText>&nbsp;
              {displayValues.height}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={NumericInput}
              disabled={isFormDisabled}
              label="Height (cm)"
              name={isSubProperty ? 'patient.height' : 'height'}
              min="0"
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </div>
        <div>
          {isFormDisabled && (
            <>
              <StyledBoldText>Gender:</StyledBoldText>&nbsp;
              {displayValues.gender}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={DropdownWithValuesField}
              data={PatientGenders}
              dataItemKey="value"
              disabled={isFormDisabled}
              isForPrimitiveValues
              label="Gender"
              name={isSubProperty ? 'patient.gender' : 'gender'}
              valueField="value"
            />
          </StyledFormFieldContainer>
        </div>
        <div>
          {isFormDisabled && (
            <>
              <StyledBoldText>Cross Clamp Time:</StyledBoldText>&nbsp;
              {PatientUtils.formatCrossClampDateTime(displayValues.crossClampDateTime?.toISOString() ?? null)}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={DateTimePicker}
              disabled={isFormDisabled}
              label="Cross Clamp Time"
              name={isSubProperty ? 'patient.crossClampDateTime' : 'crossClampDateTime'}
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </div>
        <div>
          {isFormDisabled && (
            <>
              <StyledBoldText>Hospital:</StyledBoldText>&nbsp;
              {displayValues.hospital}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={Input}
              disabled={isFormDisabled}
              label="Hospital"
              name={isSubProperty ? 'patient.hospital' : 'hospital'}
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </div>
        {isEditMode && (
          <>
            {isFormDisabled ? (
              <StyledBoldCol bold={isFormDisabled}>
                <StyledBoldText>Active:</StyledBoldText>&nbsp;
                {displayValues.active ? 'Yes' : 'No'}
              </StyledBoldCol>
            ) : (
              <StyledBoldCol bold={isFormDisabled}>
                <Field component={Switch} disabled={isFormDisabled} label="Active" name={isSubProperty ? 'patient.active' : 'active'} />
              </StyledBoldCol>
            )}
          </>
        )}

        <div>
          {isFormDisabled && (
            <>
              <StyledBoldText>Notes:</StyledBoldText>&nbsp;
              {displayValues.notes}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field component={TextArea} disabled={isFormDisabled} label="Notes" name={isSubProperty ? 'patient.notes' : 'notes'} onChange={handleFormChange} />
          </StyledFormFieldContainer>
        </div>
      </StyledFormDiv>
    </>
  );
};

const StyledBoldCol = styled.div<{ bold: boolean }>`
  .k-label {
    font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  }
`;

const StyledBoldText = styled.b``;

const StyledCheckbox = styled(Checkbox)`
  display: inline-flex;
  gap: ${({ theme }) => theme.space.spacing20};
`;

const StyledUnosUnavailableField = styled(Field)`
  padding-bottom: ${({ theme }) => theme.space.spacing40};
`;

const StyledFormFieldContainer = styled.div<{ isFormDisabled: boolean }>`
  display: ${({ isFormDisabled }) => (isFormDisabled ? 'none' : 'block')};
`;

const StyledFormDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  > * {
    min-width: 220px;
  }
`;
