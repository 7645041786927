import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { QueryModel } from 'models';
import { DCMStudyInstanceModel } from 'models/DCMStudyInstanceModel';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class DICOMClient {
  constructor(private httpClient: HttpClient) {
    this.getAllSourcesForKendoGrid = this.getAllSourcesForKendoGrid.bind(this);
  }
  public async getAllSourcesForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<DataResult<DCMStudyInstanceModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/DICOM/grid?${queryStr}`, options),
    );
    return result;
  }

  public async query(query: QueryModel) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    console.log('query', query);

    const queryValues = Object.fromEntries(Object.entries(query).filter(([_, value]) => value !== undefined));
    console.log('queryValues', queryValues);

    const queryStr = new URLSearchParams(queryValues as Record<string, string>).toString();
    const result = await this.httpClient.fetchAndParse<QueryModel>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/DICOM/query?${queryStr}`, options));
    return result;
  }

  public async import(destinationId: number, studyInstanceUids: string[]) {
    const options = await this.httpClient.createStandardOptions('POST', { destinationId, studyInstanceUids });
    console.log('import', destinationId, studyInstanceUids);

    const result = await this.httpClient.fetchAndParse<QueryModel>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/DICOM/import`, options));
    return result;
  }
}
