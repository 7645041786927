import { useCallback, useRef, useState } from 'react';

import { Button } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { Popover, PopoverActionsBar } from '@progress/kendo-react-tooltip';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonVariants, Button as CoreButton, Dialog } from 'core/ui';

import ThumbnailIcon from '../assets/thumbnail.svg?react';

const ThumbnailPlaceholder = ({ className, onRemove, showEditBtn, showDeleteBtn, fileItem, onClickYesBtn, enableDelete = true, examType }) => {
  const anchor = useRef(null);
  const [show, setShow] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [message, setMessage] = useState(fileItem.fileName);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleRemove = () => {
    if (onRemove) {
      onRemove(fileItem.id);
    }
  };

  const toggleDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const onClick = useCallback(() => {
    setShow(!show);
  }, [setShow, show]);
  return (
    <StyledBackgroundDiv className={className}>
      {showDeleteBtn && (
        <Button fillMode="outline" themeColor="error" className="buttons-container-button float-end" iconClass="fa fa-trash" onClick={toggleDialog} />
      )}
      {showDeleteDialog && (
        <Dialog title="Please Confirm" onClose={toggleDialog}>
          <span>Delete file</span>
          <DialogActionsBar>
            <CoreButton onClick={toggleDialog} variant={ButtonVariants.SECONDARY}>
              No
            </CoreButton>
            <CoreButton onClick={handleRemove}>Yes</CoreButton>
          </DialogActionsBar>
        </Dialog>
      )}
      {showEditBtn && (
        <>
          <Button fillMode="outline" className="buttons-container-button float-end" iconClass="fa fa-pencil" onClick={onClick} ref={anchor} />
          <Popover show={show} anchor={anchor.current && anchor.current.element} title="Rename File">
            <Input name="filename" label="Filename" defaultValue={fileItem.fileName} onChange={handleChange} />
            <PopoverActionsBar>
              <Button onClick={() => onClickYesBtn(message)} themeColor="success">
                OK
              </Button>
              <Button onClick={onClick} themeColor="error">
                Cancel
              </Button>
            </PopoverActionsBar>
          </Popover>
        </>
      )}
      <StyledFlexDiv>
        <ThumbnailIcon />
        <StyledDivText>{examType}</StyledDivText>
      </StyledFlexDiv>
    </StyledBackgroundDiv>
  );
};

const StyledBackgroundDiv = styled.div`
  background: ${({ theme }) => theme.colors.palette.white};
  border-radius: 2px;
`;

const StyledFlexDiv = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.space.spacing40};
  padding-top: ${({ theme }) => theme.space.spacing70};
  text-align: center;
`;

const StyledDivText = styled.div`
  color: ${({ theme }) => theme.colors.palette.grayscale[7]};
  font-size: ${({ theme }) => theme.fontSizes.footnote};
  margin-top: ${({ theme }) => theme.space.spacing50};
  margin-bottom: ${({ theme }) => theme.space.spacing50};
  text-align: center;
`;

ThumbnailPlaceholder.propTypes = {
  className: PropTypes.string,
  onRemove: PropTypes.func,
  showDeleteBtn: PropTypes.bool,
  showEditBtn: PropTypes.bool,
  fileItem: PropTypes.shape({
    fileId: PropTypes.number,
    thumbnailUrl: PropTypes.string,
    dateModified: PropTypes.string,
    fileName: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  onClickYesBtn: PropTypes.func,
  enableDelete: PropTypes.bool,
  examType: PropTypes.string,
};

ThumbnailPlaceholder.defaultProps = {
  className: '',
  onRemove: () => {},
  showDeleteBtn: false,
  showEditBtn: false,
  fileItem: {},
  onClickYesBtn: () => {},
  enableDelete: true,
  examType: '',
};

export { ThumbnailPlaceholder };
