import { datadogLogs } from '@datadog/browser-logs';
import { CompressCompleteEvent, CompressErrorEvent, CompressProgressEvent, CompressStartEvent } from '@repo/web-workers/file-compressor';

import { EventStream, IEventStreamConsumer, ParallelQueue } from 'core/utils';

export type FileCompressorJob = {
  fileId: string;
  file: File;
};

export class FileCompressor {
  static [Symbol.toStringTag]() {
    return 'FileCompressor';
  }

  private _queue: ParallelQueue<FileCompressorJob>;

  private _streams = {
    onComplete: new EventStream<CompressCompleteEvent>(),
    onError: new EventStream<CompressErrorEvent>(),
  };

  public get streams(): {
    onComplete: IEventStreamConsumer<CompressCompleteEvent>;
    onError: IEventStreamConsumer<CompressErrorEvent>;
  } {
    return this._streams;
  }

  constructor() {
    this.initialize = this.initialize.bind(this);
    this.destroy = this.destroy.bind(this);
    this.enqueueFiles = this.enqueueFiles.bind(this);
    this.compressFile = this.compressFile.bind(this);

    this._queue = new ParallelQueue<FileCompressorJob>({ key: 'fileId', maxRunners: 10, run: this.compressFile });
  }

  public initialize() {
    // No-op for now.
  }

  /** Terminates the web worker.  Safe to call if not currently running. */
  public destroy() {
    this._streams.onComplete.clear();
    this._streams.onError.clear();
  }

  public enqueueFiles(files: FileCompressorJob[]) {
    this._queue.enqueue(files);
    this._queue.run();
  }

  private async compressFile(job: FileCompressorJob) {
    const compressStartOffset = performance.now();
    let endMark: PerformanceMark | null = null;
    let measure: PerformanceMeasure | null = null;
    let success = false;

    const startMark = performance.mark(`compress-file:start:${job.fileId}`, {
      detail: { fileId: job.fileId, fileName: job.file.name, fileSize: job.file.size },
    });

    try {
      // use compression stream api
      const stream = job.file.stream();
      const compressedStream = stream.pipeThrough<Uint8Array>(new CompressionStream('deflate'));
      const result = await new Response(compressedStream).arrayBuffer();

      endMark = performance.mark(`compress-file:end:${job.fileId}`, { detail: { fileId: job.fileId, fileName: job.file.name, fileSize: job.file.size } });

      success = true;

      measure = performance.measure(`compress-file:${job.fileId}`, {
        detail: { fileId: job.fileId, fileName: job.file.name, fileSize: job.file.size },
        start: startMark.name,
        end: endMark.name,
      });

      this._streams.onComplete.emit({
        fileId: job.fileId,
        fileName: job.file.name,
        buffer: result,
        compressedSize: result.byteLength,
        fileSize: job.file.size,
        duration: measure.duration,
      });
    } catch (error) {
      if (endMark == null) {
        endMark = performance.mark(`compress-file:end:${job.fileId}`, { detail: { fileId: job.fileId, fileName: job.file.name, fileSize: job.file.size } });
      }

      if (measure == null) {
        measure = performance.measure(`compress-file:${job.fileId}`, {
          detail: { fileId: job.fileId, fileName: job.file.name, fileSize: job.file.size },
          start: startMark.name,
          end: endMark.name,
        });
      }

      this._streams.onError.emit({
        fileId: job.fileId,
        fileName: job.file.name,
        error,
        duration: measure.duration,
      });
    } finally {
      if (endMark == null) {
        endMark = performance.mark(`compress-file:end:${job.fileId}`, { detail: { fileId: job.fileId, fileName: job.file.name, fileSize: job.file.size } });
      }

      if (measure == null) {
        measure = performance.measure(`compress-file:${job.fileId}`, {
          detail: { fileId: job.fileId, fileName: job.file.name, fileSize: job.file.size },
          start: startMark.name,
          end: endMark.name,
        });
      }

      datadogLogs.logger.info('compress-file', {
        fileId: job.fileId,
        timestamp: new Date(performance.timeOrigin + startMark.startTime).toISOString(),
        duration: measure.duration * 1000000, // Datadog expects durations to be in nanoseconds.
        success,
      });
    }
  }
}
