import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';
import { ReadRequestFormEdit } from 'features/read-request';

import { Approve, ExamRead } from '../../exam-reading';
import { ExamRoutes } from '../routes';
import { ExamHome } from './ExamHome';
import { ExamProcessing } from './ExamProcessing';

export const Exam = () => {
  return (
    <Routes>
      <Route
        path={ExamRoutes.HOME}
        element={
          <RouteGuard allowedRoles={[UserRoles.ADMIN, UserRoles.CUSTOMER_ADMIN, UserRoles.CUSTOMER]}>
            <ExamHome />
          </RouteGuard>
        }
      />
      <Route
        path={ExamRoutes.ADD}
        element={
          <RouteGuard allowedRoles={[UserRoles.ADMIN, UserRoles.CUSTOMER_ADMIN, UserRoles.CUSTOMER]}>
            <ExamProcessing />
          </RouteGuard>
        }
      />
      <Route
        path={ExamRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={[UserRoles.ADMIN, UserRoles.CUSTOMER_ADMIN, UserRoles.CUSTOMER]}>
            <ExamProcessing />
          </RouteGuard>
        }
      />
      <Route
        path={ExamRoutes.APPROVE}
        element={
          <RouteGuard allowedRoles={[UserRoles.ADMIN, UserRoles.CUSTOMER_ADMIN, UserRoles.PHYSICIAN]}>
            <Approve />
          </RouteGuard>
        }
      />
      <Route
        path={ExamRoutes.READ}
        element={
          <RouteGuard allowedRoles={[UserRoles.ADMIN, UserRoles.CUSTOMER_ADMIN, UserRoles.PHYSICIAN]}>
            <ExamRead />
          </RouteGuard>
        }
      />
      <Route
        path={ExamRoutes.READREQUEST}
        element={
          <RouteGuard allowedRoles={[UserRoles.ADMIN, UserRoles.CUSTOMER_ADMIN, UserRoles.PHYSICIAN, UserRoles.CUSTOMER]}>
            <ReadRequestFormEdit />
          </RouteGuard>
        }
      />
    </Routes>
  );
};
