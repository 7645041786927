import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import cornerstone from 'cornerstone-core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dayjs from 'dayjs';
import dicomParser from 'dicom-parser';

import { EventStream, IEventStreamConsumer, PriorityQueue, equalsInsensitive, getFileExtension, hasText, lookupMimeType } from 'core/utils';

import {
  DATE_PARSE_FORMATS,
  DICOM_UNCOMPRESSED_TRANSFER_SYNTAXES,
  NORMALIZED_DATE_FORMAT,
  NORMALIZED_TIME_FORMAT,
  PARSE_DICOM_CARET_DELIMITED_TAGS,
  PARSE_DICOM_DATE_TAGS,
  PARSE_DICOM_METADATA_FILE_DATA_OPTIONS,
  PARSE_DICOM_METADATA_OPTIONS,
  PARSE_DICOM_TAGS,
  PARSE_DICOM_TIME_TAGS,
  TIME_PARSE_FORMATS,
} from '../constants';
import {
  FileScannerJob,
  FileScannerJobDicom,
  FileScannerJobGenerateThumbnail,
  FileScannerJobNonDicom,
  ParseCompleteEvent,
  ParsedDicomMetadata,
  ThumbnailReadyEvent,
} from '../types';
import { ImageDataCache } from './ImageDataCache';
import { FileService } from './file-service';

const THUMBNAIL_WIDTH = 120;
const THUMBNAIL_HEIGHT = 140;

/** Minimum DICOM file size in order to potentially trigger an uncompressed image warning. */
const UNCOMPRESSED_DICOM_WARNING_MINIMUM_SIZE = 2 ** 20; // 1 MiB

const config = {
  maxWebWorkers: navigator.hardwareConcurrency || 1,
  startWebWorkersOnDemand: true,
  taskConfiguration: {
    decodeTask: {
      initializeCodecsOnStartup: false,
    },
  },
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
cornerstoneWADOImageLoader.webWorkerManager.initialize(config);
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

export class FileScanner {
  static [Symbol.toStringTag]() {
    return 'FileScanner';
  }

  /** The key is the SeriesInstanceUID.  The value is the browser generated random id assigned to a series. */
  private _dicomSeriesIdLookup = new Map<string, string>();

  private _queue: PriorityQueue<FileScannerJob>;

  private _streams = {
    onParseComplete: new EventStream<ParseCompleteEvent>(),
    onThumbnailReady: new EventStream<ThumbnailReadyEvent>(),
  };

  public get streams(): {
    onParseComplete: IEventStreamConsumer<ParseCompleteEvent>;
    onThumbnailReady: IEventStreamConsumer<ThumbnailReadyEvent>;
  } {
    return this._streams;
  }

  constructor(private _thumbnailCache: ImageDataCache) {
    this.initialize = this.initialize.bind(this);
    this.destroy = this.destroy.bind(this);
    this.enqueueDicomFiles = this.enqueueDicomFiles.bind(this);
    this.enqueueNonDicomFiles = this.enqueueNonDicomFiles.bind(this);
    this.scanDicomFile = this.scanDicomFile.bind(this);
    this.extractDicomMetadata = this.extractDicomMetadata.bind(this);
    this.generateThumbnail = this.generateThumbnail.bind(this);
    this.run = this.run.bind(this);

    this._queue = new PriorityQueue<FileScannerJob>({
      key: 'fileId',
      compare: (lhs, rhs) => (lhs.type === rhs.type ? 0 : lhs.type === 'scan-dicom-file' ? -1 : 1), // Extracting DICOM metadata is first priority.
      run: this.run,
    });
  }

  public initialize() {
    // No-op
  }

  public destroy() {
    this._dicomSeriesIdLookup.clear();
    this._streams.onParseComplete.clear();
    this._streams.onThumbnailReady.clear();
    this._thumbnailCache.clear();
    this._queue.destroy();
  }

  public enqueueDicomFiles(files: FileScannerJobDicom | FileScannerJobDicom[]) {
    this._queue.enqueue(files);

    // Defer execution of the file scanner loop to the next event loop cycle.  This will help keep the UI responsive.
    setTimeout(() => {
      this._queue.run();
    });
  }

  public enqueueNonDicomFiles(files: FileScannerJobNonDicom[]) {
    // For now we are not going to really do any scanning for non-dicom files.  We will simply emit a parse-complete event for each file.
    // In the future we will likely want to add thumbnail generation for non-dicom files as well.

    // Defer execution of the file scanner loop to the next event loop cycle.  This will help keep the UI responsive.
    setTimeout(() => {
      for (const file of files) {
        this._streams.onParseComplete.emit({
          fileId: file.fileId,
          seriesId: null,
          file: file.file,
          dicomData: null,
          uncompressedDicomWarning: false,
          uploadEligible: true,
        });
      }
    });
  }

  private async run(job: FileScannerJob) {
    if (job.type === 'scan-dicom-file') {
      const result = await this.scanDicomFile(job);

      // Add to the thumbnail queue if the file is a DICOM file and it has a series.
      if (result?.seriesId != null) {
        this._queue.enqueue({
          type: 'generate-thumbnail',
          fileId: job.fileId,
          event: result,
        });
      }

      return result;
    } else if (job.type === 'generate-thumbnail') {
      const result = await this.generateThumbnail(job);
      return result;
    }
  }

  private async scanDicomFile(file: FileScannerJobDicom): Promise<ParseCompleteEvent | null> {
    let completeEvent: ParseCompleteEvent;
    let endMark: PerformanceMark | null = null;
    let measure: PerformanceMeasure | null = null;
    let success = false;

    const startMark = performance.mark(`parse-dicom:start:${file.fileId}`, {
      detail: { fileId: file.fileId, fileName: file.file.name, fileSize: file.file.size },
    });

    try {
      const dicomData = await this.extractDicomMetadata(file.file);

      endMark = performance.mark(`parse-dicom:end:${file.fileId}`, { detail: { fileId: file.fileId } });

      success = true;

      // Skip files that are not DICOM.
      if (dicomData == null) {
        completeEvent = {
          fileId: file.fileId,
          seriesId: null,
          file: file.file,
          dicomData: null,
          uncompressedDicomWarning: false,
          uploadEligible: false,
        };

        this._streams.onParseComplete.emit(completeEvent);
        return null;
      }

      // Check if the file is an uncompressed DICOM file.  We only want to warn the user if the file is relatively large.
      const uncompressedDicomWarning =
        file.file.size >= UNCOMPRESSED_DICOM_WARNING_MINIMUM_SIZE && DICOM_UNCOMPRESSED_TRANSFER_SYNTAXES.includes(dicomData.TransferSyntaxUID!);

      let seriesId = this._dicomSeriesIdLookup.get(dicomData.SeriesInstanceUID!) ?? null;

      if (seriesId == null && hasText(dicomData.SeriesInstanceUID)) {
        seriesId = crypto.randomUUID();
        this._dicomSeriesIdLookup.set(dicomData.SeriesInstanceUID, seriesId);
        this._thumbnailCache.set(seriesId, null);
      }

      completeEvent = {
        fileId: file.fileId,
        seriesId,
        file: file.file,
        dicomData: dicomData,
        uncompressedDicomWarning,
        uploadEligible: true,
      };

      this._streams.onParseComplete.emit(completeEvent);
    } catch (error) {
      if (endMark == null) {
        endMark = performance.mark(`parse-dicom:end:${file.fileId}`, { detail: { fileId: file.fileId } });
      }

      // File is not DICOM.  For now our intendend behavior is to skip such files.  This may change in the future.
      datadogLogs.logger.error('Error parsing file.', { name: file.file.name ?? null }, error as Error | undefined);

      completeEvent = {
        fileId: file.fileId,
        seriesId: null,
        file: file.file,
        dicomData: null,
        uncompressedDicomWarning: false,
        uploadEligible: false,
      };

      this._streams.onParseComplete.emit(completeEvent);

      return null;
    } finally {
      if (endMark == null) {
        endMark = performance.mark(`parse-dicom:end:${file.fileId}`, { detail: { fileId: file.fileId } });
      }

      measure = performance.measure(`parse-dicom:${file.fileId}`, {
        detail: { fileId: file.fileId, fileName: file.file.name, fileSize: file.file.size },
        start: startMark.name,
        end: endMark.name,
      });

      datadogLogs.logger.info('parse-dicom', {
        fileId: file.fileId,
        timestamp: new Date(performance.timeOrigin + startMark.startTime).toISOString(),
        duration: measure.duration * 1000000, // Datadog expects durations to be in nanoseconds.
        success,
      });
    }

    return completeEvent;
  }

  private async generateThumbnail(job: FileScannerJobGenerateThumbnail) {
    // TODO: This method is very slow - to the point where it can cause the UI to hang.  This can be greatly improved by using a web worker to
    // generate the thumbnail.  However that may require us to upgrade to the newly-released Cornerstone3D library.  That library currently has
    // a bug preventing us from using it: https://github.com/cornerstonejs/cornerstone3D/issues/1071.

    if (job.event.seriesId == null) return;

    // Skip generating the thumbnail if it has already been generated.
    if (this._thumbnailCache.get(job.event.seriesId)) return;

    let hiddenDiv: HTMLDivElement | null = null;
    let hiddenCanvas: HTMLCanvasElement | null = null;
    let endMark: PerformanceMark | null = null;
    let measure: PerformanceMeasure | null = null;
    let success = false;

    let renderPromiseResolve: () => void;
    const renderPromise = new Promise<void>((resolve) => {
      renderPromiseResolve = resolve;
    });
    const handleRenderComplete = () => {
      renderPromiseResolve();
    };

    const hiddenDivCleanup = () => {
      if (hiddenDiv != null) {
        cornerstone.disable(hiddenDiv);
        hiddenDiv.removeEventListener('cornerstoneimagerendered', handleRenderComplete);
        hiddenDiv.remove();
        hiddenDiv = null;
        hiddenCanvas = null;
      }
    };

    const startMark = performance.mark(`generate-thumbnail:start:${job.event.fileId}`, {
      detail: { fileId: job.fileId, fileName: job.event.file.name, fileSize: job.event.file.size },
    });

    // Generate the thumbnail.
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const thumbnailImageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(job.event.file);
      const thumbnail = thumbnailImageId == null ? null : await cornerstone.loadImage(thumbnailImageId);

      if (thumbnail == null) {
        datadogLogs.logger.error('Thumbnail generation returned null or undefined result.', {
          name: job.event.file?.name ?? null,
          fileId: job.event.fileId,
        });
        return;
      }

      // Render the thumbnail to a hidden canvas so we can capture the raw ImageData - allowing us to cache the small thumbnail
      // and avoid re-rendering the full-sized DICOM image.
      hiddenDiv = document.createElement('div');
      hiddenDiv.style.position = 'absolute';
      hiddenDiv.style.visibility = 'hidden';
      hiddenDiv.style.width = `${THUMBNAIL_WIDTH}px`;
      hiddenDiv.style.height = `${THUMBNAIL_HEIGHT}px`;
      hiddenDiv.addEventListener('cornerstoneimagerendered', handleRenderComplete);

      hiddenCanvas = document.createElement('canvas');
      hiddenCanvas.className = 'cornerstone-canvas';
      hiddenCanvas.style.width = '100%';
      hiddenCanvas.style.height = '100%';
      hiddenCanvas.style.objectFit = 'contain';
      hiddenCanvas.width = THUMBNAIL_WIDTH;
      hiddenCanvas.height = THUMBNAIL_HEIGHT;

      hiddenDiv.insertBefore(hiddenCanvas, null);
      document.body.insertBefore(hiddenDiv, null);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cornerstone.enable(hiddenDiv, { renderer: 'webgl', desynchronize: true } as unknown as any);
      cornerstone.displayImage(hiddenDiv, thumbnail);

      // We have to wait until after cornerstone has emitted the 'cornerstoneimagerendered' event before we can capture the image data.
      await renderPromise;

      const bitmap = hiddenCanvas.getContext('2d')!.getImageData(0, 0, THUMBNAIL_WIDTH, THUMBNAIL_HEIGHT);
      this._thumbnailCache.set(job.event.seriesId, bitmap);
      hiddenDivCleanup();

      endMark = performance.mark(`generate-thumbnail:end:${job.event.fileId}`, { detail: { fileId: job.event.fileId } });

      success = true;

      this._streams.onThumbnailReady.emit({
        type: 'thumbnail-ready',
        fileId: job.event.fileId,
        image: thumbnail,
      });
    } catch (error) {
      if (endMark == null) {
        endMark = performance.mark(`generate-thumbnail:end:${job.event.fileId}`, { detail: { fileId: job.event.fileId } });
      }

      const errorMessage = error != null && typeof error === 'object' && 'error' in error && error.error instanceof Error ? error.error.message : null;

      hiddenDivCleanup();

      // Ignore the error if the file is valid DICOM, but simply does not have image data.  This is a common scenario for DICOM files.
      if (equalsInsensitive(errorMessage, 'The file does not contain image data.')) return;

      // Ignore errors when the embedded image data is not in a format that Cornerstone can render.  Videos in particular.
      if (errorMessage?.startsWith?.('No decoder for transfer syntax')) return;

      datadogLogs.logger.error('Error while generating thumbnail:', { name: job.event.file?.name ?? null }, error as Error | undefined);
      return;
    } finally {
      if (endMark == null) {
        endMark = performance.mark(`generate-thumbnail:end:${job.event.fileId}`, { detail: { fileId: job.event.fileId } });
      }

      measure = performance.measure(`generate-thumbnail:${job.event.fileId}`, {
        detail: { fileId: job.event.fileId, fileName: job.event.file.name, fileSize: job.event.file.size },
        start: startMark.name,
        end: endMark.name,
      });

      datadogLogs.logger.info('generate-thumbnail', {
        fileId: job.event.fileId,
        timestamp: new Date(performance.timeOrigin + startMark.startTime).toISOString(),
        duration: measure.duration * 1000000, // Datadog expects durations to be in nanoseconds.
        success,
      });
    }
  }

  private async extractDicomMetadata(file: File): Promise<ParsedDicomMetadata | null> {
    // Do an initial check to see if the file is a DICOM file based on the file extension.  This won't catch everything because
    // some DICOM files don't have a file extension.
    const fileExtension = getFileExtension(file.name);
    if (fileExtension != null && !(lookupMimeType('.dcm')?.extensions ?? []).some((ext) => equalsInsensitive(ext, fileExtension))) {
      return null;
    }

    const readBuffer: Uint8Array = await new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (onLoadEvent) => {
        if (onLoadEvent.target == null) throw new Error('onLoadEvent.target is null');
        if (onLoadEvent.target.result == null) throw new Error('onLoadEvent.target.result is null');
        if (typeof onLoadEvent.target.result === 'string') throw new Error('onLoadEvent.target.result is a string instead of an ArrayBuffer.');

        resolve(new Uint8Array(onLoadEvent.target.result));
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsArrayBuffer(file);
    });

    try {
      const dataSet = dicomParser.parseDicom(readBuffer, PARSE_DICOM_METADATA_OPTIONS);
      const fileData = dicomParser.explicitDataSetToJS(dataSet, PARSE_DICOM_METADATA_FILE_DATA_OPTIONS);

      if (typeof fileData === 'string' || Array.isArray(fileData)) {
        throw new Error(
          `Unexpected data structure encountered during DICOM file parsing.  Data contains a ${
            typeof fileData === 'string' ? 'string' : 'array'
          } when an object was expected.`,
        );
      }

      const parsed: ParsedDicomMetadata = {
        AccessionNumber: null,
        BodyPartExamined: null,
        StudyDescription: null,
        PatientID: null,
        PatientBirthDate: null,
        PatientSex: null,
        PatientName: [],
        StudyDate: null,
        StudyTime: null,
        StudyInstanceUID: null,
        Modality: null,
        SeriesInstanceUID: null,
        SeriesDescription: null,
        TransferSyntaxUID: null,
      };

      for (const tag of PARSE_DICOM_TAGS) {
        const tagKey = FileService.formatDicomTagKey(tag.tag, 'leading-x');
        const tagValue = (fileData as unknown as Record<string, string>)[tagKey] ?? null; // Type cast because the dicomParser library doesn't have accurate typings.

        if (tag.name in parsed) {
          if (PARSE_DICOM_CARET_DELIMITED_TAGS.some((t) => FileService.formatDicomTagKey(t.tag, 'leading-x') === tagKey)) {
            // Parse caret delimited values into an array of strings.
            Object.assign(parsed, {
              [tag.name]: hasText(tagValue)
                ? tagValue
                    .split('^')
                    .map((v) => v.trim())
                    .filter((v) => hasText(v))
                : [],
            });
          } else if (PARSE_DICOM_DATE_TAGS.some((t) => FileService.formatDicomTagKey(t.tag, 'leading-x') === tagKey)) {
            // Parse date-only values into a normalized format.
            const parsedDate = dayjs(tagValue, DATE_PARSE_FORMATS, true);

            if (parsedDate.isValid()) {
              Object.assign(parsed, { [tag.name]: parsedDate.format(NORMALIZED_DATE_FORMAT) });
            } else {
              // Intentionally throwing away the value if it cannot be parsed as a valid date.  The user will have to manually enter the correct date.
              //datadogLogs.logger.error(`Could not parse date for tag: ${tag.tag} ${tag.name}.`);
            }
          } else if (PARSE_DICOM_TIME_TAGS.some((t) => FileService.formatDicomTagKey(t.tag, 'leading-x') === tagKey)) {
            // Parse time-only values into a normalized format.
            const parsedTime = dayjs(tagValue, TIME_PARSE_FORMATS, true);

            if (parsedTime.isValid()) {
              Object.assign(parsed, { [tag.name]: parsedTime.format(NORMALIZED_TIME_FORMAT) });
            } else {
              // Intentionally throwing away the value if it cannot be parsed as a valid time.  The user will have to manually enter the correct time.
              datadogLogs.logger.error(`Could not parse time for tag: ${tag.tag} ${tag.name}.`);
            }
          } else {
            // Parse all other values with only basic whitespace trimming.
            Object.assign(parsed, { [tag.name]: hasText(tagValue) ? tagValue.trim() : null });
          }
        } else {
          throw new Error(`Could not find a matching parse destination property for tag: ${tag.tag} ${tag.name}.`);
        }
      }

      return parsed;
    } catch (exception) {
      datadogLogs.logger.error('Error while reading file.', undefined, exception as Error | undefined);
      return null;
    }
  }
}
