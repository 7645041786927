import { roundNumber } from './roundNumber';

/** Format number of bytes to a human readable string.
 * @param si Whether to use SI thresholds.  For example 1 kB = 1000 bytes (SI) vs 1KiB = 1024 bytes (IEC).  Default set to `false`.
 */
export function formatFileSize(bytes: number, si = false, decimals = 1) {
  // https://stackoverflow.com/a/14919494

  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;

  do {
    // eslint-disable-next-line no-param-reassign
    bytes /= thresh;
    ++u;
  } while (roundNumber(Math.abs(bytes), decimals) >= thresh && u < units.length - 1);

  return `${bytes.toFixed(decimals)} ${units[u]}`;
}
