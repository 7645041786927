import { memo, useEffect, useRef } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import styled from 'styled-components';

import { useDataStreamMap, useEvent } from 'core/hooks';

import { useUploadPipeline } from 'features/file';

import { SeriesThumbnailProps } from '../types';

export const SeriesThumbnail = memo<SeriesThumbnailProps>(({ className, uploadGroup, seriesIndex }) => {
  const series = uploadGroup.series[seriesIndex];
  const { thumbnailCache } = useUploadPipeline();

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const thumbnailImageData = useDataStreamMap(series.seriesId, thumbnailCache.streams.bitmaps);

  const renderThumbnail = useEvent(async () => {
    if (thumbnailImageData == null) return;

    const imageBitmap = await createImageBitmap(thumbnailImageData);

    // We need to check if the canvasRef is null only AFTER the ImageBitmap has been created.  Because the
    // component may have been unmounted while the call to createImageBitmap() was awaited.
    if (canvasRef.current == null) return;

    const context = canvasRef.current.getContext('bitmaprenderer');

    if (context == null) {
      // No idea how this could happen.  But thumbnails are not critical to the app so we'll just log and hide the issue from the user.
      datadogLogs.logger.error('Failed to get bitmaprenderer context for canvas element.', {
        details: { seriesId: series.seriesId, seriesInstanceUid: series.dicomData?.SeriesInstanceUID ?? undefined },
      });
      return;
    }

    context.transferFromImageBitmap(imageBitmap);
  });

  useEffect(() => {
    renderThumbnail();
  }, [renderThumbnail, thumbnailImageData]);

  if (thumbnailImageData == null) return null;

  return (
    <StyledComponentDiv className={className} data-series-id={series.seriesId} data-series-instance-uid={series.dicomData?.SeriesInstanceUID ?? undefined}>
      <StyledThumbnailContainerDiv>
        <canvas ref={canvasRef} width={120} height={140} />
      </StyledThumbnailContainerDiv>
    </StyledComponentDiv>
  );
});

SeriesThumbnail.displayName = 'SeriesThumbnail';

const StyledComponentDiv = styled.div``;

const StyledThumbnailContainerDiv = styled.div`
  // Note: The cornerstone library requires that the direct parent of the canvas element has a fixed width and height.
  width: 120px;
  height: 140px;
  overflow: hidden;

  canvas {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;
