import { ExamMatchQueryModel, ExamModel, PatientMatchQueryModel, PatientModel } from 'models';

import { EventStream, IEventStreamConsumer, ParallelQueue, equalsInsensitive } from 'core/utils';

import { apiClient } from 'features/api';

import { DicomMatcherJob, MatchCompleteEvent } from '../types';

type PatientMatchCacheEntry = {
  query: PatientMatchQueryModel;
  /** Matched patient record.  Note: `null` indicates that a match query has been executed and yielded no matches.  We must store this "no matches" so that we don't hammer the server with the same match query. */
  patient: PatientModel | null;
};

type ExamMatchCacheEntry = {
  query: ExamMatchQueryModel;
  /** Matched exam record.  Note: `null` indicates that a match query has been executed and yielded no matches.  We must store this "no matches" so that we don't hammer the server with the same match query. */
  exam: ExamModel | null;
};

export class DicomMatcher {
  static [Symbol.toStringTag]() {
    return 'DicomMatcher';
  }

  private _queue: ParallelQueue<DicomMatcherJob>; // Note: This has to be initialized inside the constructor because it will reference a callback method that is bound to the class instance.

  private _fixedPatient: PatientModel | null = null;

  private _fixedExam: ExamModel | null = null;

  private _fixedLocationId: number | null = null;

  private _patientMatchCache: PatientMatchCacheEntry[] = [];

  private _examMatchCache: ExamMatchCacheEntry[] = [];

  private _streams = {
    onMatchComplete: new EventStream<MatchCompleteEvent>(),
  };

  public get streams(): {
    onMatchComplete: IEventStreamConsumer<MatchCompleteEvent>;
  } {
    return this._streams;
  }

  constructor() {
    this.initialize = this.initialize.bind(this);
    this.destroy = this.destroy.bind(this);
    this.enqueue = this.enqueue.bind(this);
    this.processJob = this.processJob.bind(this);
    this.getCachedPatientMatch = this.getCachedPatientMatch.bind(this);
    this.getCachedExamMatch = this.getCachedExamMatch.bind(this);

    this._queue = new ParallelQueue<DicomMatcherJob>({ key: 'fileId', maxRunners: 1, run: this.processJob });
  }

  public initialize() {
    // No-op.
  }

  public setFixedEntities(fixedPatient: PatientModel | null, fixedExam: ExamModel | null, fixedLocationId: number | null) {
    this._fixedPatient = fixedPatient;
    this._fixedExam = fixedExam;
    this._fixedLocationId = fixedLocationId;
  }

  public destroy() {
    this._queue.destroy();
    this._streams.onMatchComplete.clear();
    this._fixedPatient = null;
    this._fixedExam = null;
    this._fixedLocationId = null;
    this._patientMatchCache = [];
    this._examMatchCache = [];
  }

  public enqueue(job: DicomMatcherJob) {
    this._queue.enqueue(job);
    this._queue.run();
  }

  private async processJob(job: DicomMatcherJob) {
    if (this._fixedLocationId == null) throw new Error('Cannot attempt to match a DICOM file without a fixed location ID.');

    let patientMatchFetch: Promise<PatientModel | null>;
    let patientQuery: PatientMatchQueryModel | null = null;
    let patientCacheHit = false;
    let examMatchFetch: Promise<ExamModel | null>;
    let examQuery: ExamMatchQueryModel | null = null;
    let examCacheHit = false;

    // Match the patient.
    if (this._fixedPatient) {
      patientMatchFetch = new Promise<PatientModel | null>((resolve) => resolve(this._fixedPatient));
    } else {
      patientQuery = {
        location_Id: this._fixedLocationId,
        patientNumber: job.dicomData.PatientID,
        unosID: null,
        firstName: job.dicomData.PatientName[1] ?? null,
        lastName: job.dicomData.PatientName[0] ?? null,
        dob: job.dicomData.PatientBirthDate ?? null,
      };
      const match = this.getCachedPatientMatch(patientQuery);

      if (match != null) {
        patientMatchFetch = new Promise<PatientModel | null>((resolve) => resolve(match.patient));
        patientCacheHit = true;
      } else {
        patientMatchFetch = apiClient.patientClient.getPatientMatch(patientQuery);
      }
    }

    // Match the exam.
    if (this._fixedExam) {
      examMatchFetch = new Promise<ExamModel | null>((resolve) => resolve(this._fixedExam));
    } else {
      examQuery = {
        location_Id: this._fixedLocationId,
        suid: job.dicomData.StudyInstanceUID,
      };
      const match = this.getCachedExamMatch(examQuery);

      if (match != null) {
        examMatchFetch = new Promise<ExamModel | null>((resolve) => resolve(match.exam));
        examCacheHit = true;
      } else {
        examMatchFetch = apiClient.exams.getExamMatch(examQuery);
      }
    }

    const [patientMatch, examMatch] = await Promise.all([patientMatchFetch, examMatchFetch]);

    if (!patientCacheHit && patientQuery != null) {
      this._patientMatchCache.push({ query: patientQuery, patient: patientMatch });
    }

    if (!examCacheHit && examQuery != null) {
      this._examMatchCache.push({ query: examQuery, exam: examMatch });
    }

    this._streams.onMatchComplete.emit({
      fileId: job.fileId,
      patient: patientMatch,
      exam: examMatch,
    });
  }

  private getCachedPatientMatch(patientQuery: PatientMatchQueryModel) {
    // Force the caller to check for a fixed patient before attempting to get a cached patient match.  Because the fixed patient does not generate a
    // match query that can be used for a cache lookup.
    if (this._fixedPatient) {
      throw new Error('Cannot get cached patient match when a fixed patient is set.');
    }

    const patientMatch = this._patientMatchCache.find((c) => DicomMatcher.isEqualPatientMatchQueries(c.query, patientQuery)) ?? null;

    return patientMatch;
  }

  private getCachedExamMatch(examQuery: ExamMatchQueryModel) {
    // Force the caller to check for a fixed exam before attempting to get a cached exam match.  Because the fixed exam does not generate a
    // match query that can be used for a cache lookup.
    if (this._fixedExam) {
      throw new Error('Cannot get cached exam match when a fixed exam is set.');
    }

    return this._examMatchCache.find((c) => DicomMatcher.isEqualExamMatchQueries(c.query, examQuery)) ?? null;
  }

  private static isEqualPatientMatchQueries(lhs: PatientMatchQueryModel, rhs: PatientMatchQueryModel) {
    if (lhs === rhs) return true;

    return (
      lhs.location_Id === rhs.location_Id &&
      equalsInsensitive(lhs.firstName, rhs.firstName) &&
      equalsInsensitive(lhs.lastName, rhs.lastName) &&
      lhs.patientNumber === rhs.patientNumber &&
      lhs.unosID === rhs.unosID &&
      lhs.dob === rhs.dob
    );
  }

  private static isEqualExamMatchQueries(lhs: ExamMatchQueryModel, rhs: ExamMatchQueryModel) {
    if (lhs === rhs) return true;

    return lhs.location_Id === rhs.location_Id && lhs.suid === rhs.suid;
  }
}
