import { useMemo, useState } from 'react';

import { UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PatientModel } from 'models';
import { PatientAgeRangeModel } from 'models/PatientAgeRangeModel';

import { useEvent } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import {
  ButtonCell,
  ComponentSizes,
  DataTable,
  DataTableVariants,
  DateCell,
  GridColumn,
  HeaderCell,
  HeaderCellSecondary,
  Icon,
  Page,
  PageHeader,
  PrimaryCard,
  TextCell,
  faUser,
} from 'core/ui';

import { useApiClient } from 'features/api';
import { useSessionLocation } from 'features/location';
import { useUserSettings } from 'features/settings';

import { ITransplantMergeModel } from '../../../models/ITransplantMergeModel';
import { PatientSelectors, usePatientSelectSearch } from '../../patient';
import { PatientFormValues } from '../types';
import { ITransplantModal } from './ITransplantModal';
import { PatientFormContent } from './PatientFormContent';

export const PatientAdd = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const { reactPatientFormPage, legacyBaseUrl } = useUserSettings(true);

  const [iTransplantData, setITransplantData] = useState<ITransplantMergeModel | null>(null);
  const [isITransplantVisible, setIsITransplantVisible] = useState(false);
  const { sessionLocation } = useSessionLocation(false);
  const totalSearchPatients = useSelector(PatientSelectors.getTotal);
  const [currentPatientFormValues, setCurrentPatientFormValues] = useState<PatientFormValues | null>();

  const handleSubmit = async (patient: { [name: string]: any }) => {
    try {
      const newPatientId = await apiClient.patientClient.createPatient(patient as PatientModel);
      NotificationsService.displaySuccess('Donor created successfully');
      navigate(`/patient-2/edit/${newPatientId}`);
    } catch (e) {
      NotificationsService.displayError('Error creating donor');
    }
  };

  const toggleITransplantDialog = () => {
    setIsITransplantVisible(!isITransplantVisible);
  };
  const handleITransplantStatusChange = (event: UploadOnStatusChangeEvent) => {
    const isUploaded = 4;
    if (event.affectedFiles[0].status === isUploaded) {
      const iTransplantModel = event.response?.response.result as ITransplantMergeModel;

      if (iTransplantModel.hasMatch) {
        NotificationsService.displaySuccess('Found existing donor match');
        navigate(`/patient-2/edit/${iTransplantModel?.currentPatient.id}`);
        setITransplantData(null);
        toggleITransplantDialog();
      } else {
        setITransplantData(iTransplantModel);
      }
    }
  };

  const handleITransplantAdd = async () => {
    if (iTransplantData?.newPatient) {
      try {
        const newPatientId = await apiClient.patientClient.createPatient(iTransplantData?.newPatient);
        NotificationsService.displaySuccess('Donor created successfully');
        navigate(`/patient-2/edit/${newPatientId}`);
        setITransplantData(null);
        toggleITransplantDialog();
      } catch (e) {
        NotificationsService.displayError('Error creating donor');
      }
    }
  };

  const handleITransplantUpdate = async () => {
    if (!iTransplantData?.currentPatient) {
      NotificationsService.displayWarning('No donor match found to update');
      return;
    }
    if (iTransplantData?.newPatient) {
      try {
        await apiClient.patientClient.updatePatient({
          ...iTransplantData?.newPatient,
          id: iTransplantData?.currentPatient.id,
        });
        NotificationsService.displaySuccess('Donor created successfully');
        navigate(`/patient-2/edit/${iTransplantData?.currentPatient.id}`);
        setITransplantData(null);
        toggleITransplantDialog();
      } catch (error) {
        NotificationsService.displayError('Error creating donor');
      }
    }
  };

  // age range of the patient
  const [ageRanges, setAgeRanges] = useState<PatientAgeRangeModel[] | undefined>();

  useMemo(() => {
    const fetchAgeRanges = async () => {
      const patientAgeRangeResponse = await apiClient.patientClient.getPatientAgeRange();

      setAgeRanges(patientAgeRangeResponse);
    };

    fetchAgeRanges();
  }, [apiClient.patientClient]);

  const {
    currentPatient,
    handleCreateNewPatientClick,
    handlePatientsSearchDataStateChange,
    patientFormState,
    patientSearchDataState,
    patientSearchText,
    searchPatients,
    setCurrentPatient,
    setPatientFormState,
    setPatientSearchText,
    setShowPatientsDialog,
    showPatientsDialog,
  } = usePatientSelectSearch(undefined, sessionLocation?.id);

  const handleChange = useEvent((values: PatientFormValues) => {
    if (currentPatientFormValues !== null) {
      if (currentPatientFormValues?.unosID && values.unosID !== currentPatientFormValues?.unosID) setPatientSearchText(values.unosID);
      else if (currentPatientFormValues?.firstName && values.firstName !== currentPatientFormValues?.firstName) setPatientSearchText(values.firstName);
      else if (currentPatientFormValues?.lastName && values.lastName !== currentPatientFormValues?.lastName) setPatientSearchText(values.lastName);
      else if (currentPatientFormValues?.patientNumber && values.patientNumber !== currentPatientFormValues?.patientNumber)
        setPatientSearchText(values.patientNumber);
      else {
        setPatientSearchText('');
        setShowPatientsDialog(false);
      }
      setShowPatientsDialog(true);
    } else {
      setPatientSearchText('');
      setShowPatientsDialog(false);
    }

    setCurrentPatientFormValues(values);
  });

  const handleSelectedPatient = useEvent((event: PatientFormValues) => {
    if (reactPatientFormPage) {
      navigate(`/patient-2/edit/${event.id}`);
    } else {
      window.location.href = `${legacyBaseUrl}/patient/edit/${event.id}`;
    }
  });

  return (
    <Page>
      <PageHeader title="Donor" showSessionLocation />
      <PrimaryCard>
        <PrimaryCard.Body>
          <PatientFormContent
            isEditMode={false}
            isFormDisabled={false}
            buttonText="Upload ITransplant"
            onSubmit={handleSubmit}
            onFormToggle={() => toggleITransplantDialog()}
            ageRanges={ageRanges}
            onChange={handleChange}
          />
          {showPatientsDialog && (
            <StyledDataTable
              pageable
              reorderable
              sortable
              total={totalSearchPatients}
              data={searchPatients}
              variant={DataTableVariants.SECONDARY}
              size={ComponentSizes.SMALL}
              {...patientSearchDataState}
              onDataStateChange={handlePatientsSearchDataStateChange}
            >
              <GridColumn
                title=""
                filterable={false}
                sortable={false}
                headerCell={HeaderCell}
                cell={(cellProps) => <ButtonCell onClick={() => handleSelectedPatient(cellProps.dataItem)} {...cellProps} />}
                width="90px"
              />
              <GridColumn cell={TextCell} field="patientNumber" filterable={false} title="Patient ID" width="120px" headerCell={HeaderCellSecondary} />
              <GridColumn cell={TextCell} field="firstName" filterable={false} title="First Name" width="130px" headerCell={HeaderCellSecondary} />
              <GridColumn cell={TextCell} field="lastName" filterable={false} headerCell={HeaderCellSecondary} title="Last Name" width="130px" />
              <GridColumn cell={DateCell} field="dob" filterable={false} format="MM/DD/YYYY" headerCell={HeaderCellSecondary} title="DOB" width="110px" />
              <GridColumn cell={TextCell} field="age" filterable={false} headerCell={HeaderCellSecondary} title="Age" width="50px" />
              <GridColumn cell={TextCell} field="gender" filterable={false} headerCell={HeaderCellSecondary} title="Gender" width="200px" />
            </StyledDataTable>
          )}
          {isITransplantVisible && (
            <ITransplantModal
              mergeDonorData={iTransplantData}
              onUpdateDonor={handleITransplantUpdate}
              onAddDonor={handleITransplantAdd}
              onStatusChange={handleITransplantStatusChange}
              toggleITransplantDialog={toggleITransplantDialog}
              visible={isITransplantVisible}
            />
          )}
        </PrimaryCard.Body>
      </PrimaryCard>
    </Page>
  );
};

const StyledDataTable = styled(DataTable)`
  top: 20px;
  width: 50%;
  left: 30%;
`;
