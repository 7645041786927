import { datadogLogs } from '@datadog/browser-logs';

import { BulkAttachFilesModel } from 'models';

import { BatchQueue, EventStream, IEventStreamConsumer } from 'core/utils';

import { apiClient } from 'features/api';

import { FileAttachedEvent } from '../types';

export class FileAttacher {
  static [Symbol.toStringTag]() {
    return 'FileAttacher';
  }

  private _streams = {
    onFileAttached: new EventStream<FileAttachedEvent>(),
  };

  public get streams(): {
    onFileAttached: IEventStreamConsumer<FileAttachedEvent>;
  } {
    return this._streams;
  }

  private _queue: BatchQueue<BulkAttachFilesModel>;

  private _authMode: 'msal-required' | 'share-required' | null = null;

  constructor() {
    this.initialize = this.initialize.bind(this);
    this.destroy = this.destroy.bind(this);
    this.attach = this.attach.bind(this);
    this.interval = this.interval.bind(this);

    this._queue = new BatchQueue({ key: 'fileId', run: this.interval });
  }

  public initialize(authMode: 'msal-required' | 'share-required') {
    this._authMode = authMode;
  }

  public destroy() {
    this._authMode = null;
    this._queue.destroy();
    this._streams.onFileAttached.clear();
  }

  public attach(attachRequest: BulkAttachFilesModel) {
    this._queue.enqueue(attachRequest);
    this._queue.run();
  }

  private async interval(requests: BulkAttachFilesModel[]) {
    if (this._authMode == null) throw new Error('Cannot attach files without an authentication mode configured.');

    await apiClient.filesClient.attachFiles(requests, this._authMode);
    datadogLogs.logger.info('Files attached.', { count: requests.length });

    for (const request of requests) {
      this._streams.onFileAttached.emit({
        fileId: request.fileId,
      });
    }
  }
}
