import { ReactNode, memo, useEffect, useMemo, useState } from 'react';

import { useEvent } from 'core/hooks';
import { NonNullableProperties } from 'core/utils';

import { apiClient } from 'features/api';
import { AuthenticationScheme, useAuthentication } from 'features/auth';

import { UploaderContext } from '../contexts';
import { AzureBlobUploader, DicomMatcher, FileAttacher, FileCompressor, FileScanner, ImageDataCache, UploadPipeline } from '../services';

export const UploadPipelineProvider = memo<{ children?: ReactNode }>(({ children }) => {
  const { activeScheme } = useAuthentication();

  const [thumbnailCache, setThumbailCache] = useState<ImageDataCache | null>(null);
  const [fileScanner, setFileScanner] = useState<FileScanner | null>(null);
  const [uploadPipeline, setUploadPipeline] = useState<UploadPipeline | null>(null);

  const initialize = useEvent(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (activeScheme == null) return () => {};

    const newThumbnailCache = new ImageDataCache();
    const newFileScanner = new FileScanner(newThumbnailCache);
    const newDicomMatcher = new DicomMatcher();
    const newFileCompressor = new FileCompressor();
    const newBlobUploader = new AzureBlobUploader();
    const newFileAttacher = new FileAttacher();
    const newUploadPipeline = new UploadPipeline(newFileScanner, newDicomMatcher, newBlobUploader, newFileCompressor, newFileAttacher);

    const getSasFn = async (containerName: string) => {
      const newSasUrl = await apiClient.filesClient.generateAzureBlobUploadSas(
        containerName,
        activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required',
      );
      return newSasUrl;
    };

    newUploadPipeline.initialize(getSasFn, activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required');

    setThumbailCache(newThumbnailCache);
    setFileScanner(newFileScanner);
    setUploadPipeline(newUploadPipeline);

    return () => {
      newUploadPipeline.destroy();

      setThumbailCache(null);
      setFileScanner(null);
      setUploadPipeline(null);
    };
  });

  useEffect(() => {
    return initialize();
  }, [initialize, activeScheme]);

  const context = useMemo(
    () => ({
      uploadPipeline,
      fileScanner,
      thumbnailCache,
    }),
    [uploadPipeline, fileScanner, thumbnailCache],
  );

  if (context.uploadPipeline == null || context.fileScanner == null || context.thumbnailCache == null) return null;

  return <UploaderContext.Provider value={context as NonNullableProperties<typeof context>}>{children}</UploaderContext.Provider>;
});

UploadPipelineProvider.displayName = 'UploadPipelineProvider';
