import { CompressCompleteEvent, CompressErrorEvent } from '@repo/web-workers/file-compressor';

import { ExamModel, PatientModel, ServiceModel } from 'models';

import { DataStream, DataStreamMap, IDataStreamConsumer, IDataStreamMapConsumer, IntervalMergeCalculator, NonNullableProperties } from 'core/utils';

import { FileAttachedEvent, FilesLoadedEvent, UploadBatchStartEvent } from 'features/file';
import { UploadPipeline } from 'features/file/services/UploadPipeline';
import { FileContext } from 'features/file/types/FileContext';
import { FileState } from 'features/file/types/FileState';
import { MatchCompleteEvent } from 'features/file/types/MatchCompleteEvent';
import { ParseCompleteEvent } from 'features/file/types/ParseCompleteEvent';
import { Series } from 'features/file/types/Series';
import { UploadCompleteEvent } from 'features/file/types/UploadCompleteEvent';
import { UploadErrorEvent } from 'features/file/types/UploadErrorEvent';
import { UploadProgressEvent } from 'features/file/types/UploadProgressEvent';
import { UploadStartEvent } from 'features/file/types/UploadStartEvent';

import { UploadGroup, UploadGroupState } from '../types';
import { ExamUploadService } from './exam-upload-service';

type GetInternalStatesResult = {
  uploadGroup: UploadGroup | null;
  uploadGroupIndex: number | null;
  uploadGroupState: UploadGroupState | null;
  fileState: FileState | null;
};

type ScanProgress = {
  /** Number of files to scan. */
  total: number;
  /** Number of files that have been scanned. */
  complete: number;
};

export class UploadView {
  static [Symbol.toStringTag]() {
    return 'AzureBlobUploader';
  }

  private _cleanupFunctions: (() => void)[] = [];

  /** The key is the fileId. */
  private _files = new Map<string, FileState>();

  /** The key is the `uploadGroupId`. */
  private _groupProcessingIntervals = new Map<string, IntervalMergeCalculator>();

  private _streams = {
    /** The key is the `uploadGroupId`. */
    groupStates: new DataStreamMap<string, UploadGroupState>(),
    groups: new DataStream<UploadGroup[]>([]),
    scanProgress: new DataStream<ScanProgress>({ total: 0, complete: 0 }),
  };

  public get streams(): {
    /** The key is the `uploadGroupId`. */
    groupStates: IDataStreamMapConsumer<string, UploadGroupState>;
    groups: IDataStreamConsumer<UploadGroup[]>;
    scanProgress: IDataStreamConsumer<ScanProgress>;
  } {
    return this._streams;
  }

  private _fixedPatient: PatientModel | null = null;

  private _fixedExam: ExamModel | null = null;

  private _fixedLocationId: number | null = null;

  private _allServices: ServiceModel[] | null = null;

  constructor(private uploadPipeline: UploadPipeline) {
    this.initialize = this.initialize.bind(this);
    this.destroy = this.destroy.bind(this);
    this.getUploadGroupByFileId = this.getUploadGroupByFileId.bind(this);
    this.mergeFileStateChanges = this.mergeFileStateChanges.bind(this);
    this.handleFilesLoaded = this.handleFilesLoaded.bind(this);
    this.handleParseComplete = this.handleParseComplete.bind(this);
    this.handleMatchComplete = this.handleMatchComplete.bind(this);
    this.handleUploadBatchStart = this.handleUploadBatchStart.bind(this);
    this.handleCompressComplete = this.handleCompressComplete.bind(this);
    this.handleCompressError = this.handleCompressError.bind(this);
    this.handleUploadStart = this.handleUploadStart.bind(this);
    this.handleUploadProgress = this.handleUploadProgress.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
    this.handleFileAttached = this.handleFileAttached.bind(this);

    this._cleanupFunctions.push(this.uploadPipeline.streams.onFilesLoaded.subscribe(this.handleFilesLoaded));
    this._cleanupFunctions.push(this.uploadPipeline.fileScanner.streams.onParseComplete.subscribe(this.handleParseComplete));
    this._cleanupFunctions.push(this.uploadPipeline.dicomMatcher.streams.onMatchComplete.subscribe(this.handleMatchComplete));
    this._cleanupFunctions.push(this.uploadPipeline.streams.onUploadBatchStart.subscribe(this.handleUploadBatchStart));
    this._cleanupFunctions.push(this.uploadPipeline.fileCompressor.streams.onComplete.subscribe(this.handleCompressComplete));
    this._cleanupFunctions.push(this.uploadPipeline.fileCompressor.streams.onError.subscribe(this.handleCompressError));
    this._cleanupFunctions.push(this.uploadPipeline.blobUploader.streams.onStart.subscribe(this.handleUploadStart));
    this._cleanupFunctions.push(this.uploadPipeline.blobUploader.streams.onProgress.subscribe(this.handleUploadProgress));
    this._cleanupFunctions.push(this.uploadPipeline.blobUploader.streams.onComplete.subscribe(this.handleUploadComplete));
    this._cleanupFunctions.push(this.uploadPipeline.blobUploader.streams.onError.subscribe(this.handleUploadError));
    this._cleanupFunctions.push(this.uploadPipeline.fileAttacher.streams.onFileAttached.subscribe(this.handleFileAttached));
  }

  public initialize(fixedLocationId: number, fixedPatient: PatientModel | null, fixedExam: ExamModel | null, allServices: ServiceModel[]) {
    this._fixedLocationId = fixedLocationId;
    this._fixedPatient = fixedPatient;
    this._fixedExam = fixedExam;
    this._allServices = allServices;
  }

  public destroy() {
    this._cleanupFunctions.forEach((cleanup) => cleanup());
    this._cleanupFunctions = [];
    this._streams.groupStates.clear();
    this._streams.groups.clear();
    this._groupProcessingIntervals.clear();
    this._fixedPatient = null;
    this._fixedExam = null;
    this._fixedLocationId = null;
    this._allServices = null;
  }

  public setUploadGroup(uploadGroup: UploadGroup) {
    const newGroups = [...this._streams.groups.getCurrentValue()];
    const groupIndex = newGroups.findIndex((g) => g.uploadGroupId === uploadGroup.uploadGroupId);

    if (groupIndex < 0) {
      throw new Error('Could not find upload group.');
    }

    newGroups[groupIndex] = uploadGroup;
    this._streams.groups.emit(newGroups);
  }

  public updateGroup(uploadGroupId: string, changes: Partial<UploadGroup>) {
    const newUploadGroups = [...this._streams.groups.getCurrentValue()];
    const groupIndex = newUploadGroups.findIndex((g) => g.uploadGroupId === uploadGroupId);

    newUploadGroups[groupIndex] = {
      ...newUploadGroups[groupIndex],
      ...changes,
    };

    this._streams.groups.emit(newUploadGroups);

    return newUploadGroups;
  }

  public setUploadGroupPatient(uploadGroupId: string, patient: PatientModel) {
    const newUploadGroups = [...this._streams.groups.getCurrentValue()];

    for (let i = 0; i < newUploadGroups.length; i++) {
      // Update the currently selected upload item to use the new patient model.  Additionally we also need to update any other references to the same patient id.
      if (newUploadGroups[i].uploadGroupId === uploadGroupId || newUploadGroups[i].patient?.id === patient.id) {
        newUploadGroups[i] = {
          ...newUploadGroups[i],
          patient: patient,
          // Overwrite all of the form states so that they use the new values.
          patientForm: ExamUploadService.patientModelToForm(patient),
          patientFormState: { isDirty: false, isValid: true, isValidating: false },
        };
      }
    }

    this._streams.groups.emit(newUploadGroups);

    return newUploadGroups;
  }

  public getUploadGroupByFileId(fileId: string, required: true): NonNullableProperties<GetInternalStatesResult>;
  public getUploadGroupByFileId(fileId: string, required: boolean): NonNullableProperties<GetInternalStatesResult> | GetInternalStatesResult;
  public getUploadGroupByFileId(fileId: string, required = false): NonNullableProperties<GetInternalStatesResult> | GetInternalStatesResult {
    const fileState = this._files.get(fileId) ?? null;

    if (required && fileState == null) throw new Error(`Could not find file state for fileId: ${fileId}.`);

    const uploadGroupId = fileState?.uploadGroupId ?? null;

    if (required && uploadGroupId == null) throw new Error(`Could not find uploadGroupId for fileId: ${fileId}.`);

    const groups = this._streams.groups.getCurrentValue();
    const groupIndex = groups.findIndex((g) => g.uploadGroupId === uploadGroupId) ?? null;
    const uploadGroup = groups[groupIndex] ?? null;

    if (required && uploadGroup == null) throw new Error(`Could not find upload group for fileId: ${fileId}.`);

    const groupState = (uploadGroupId == null ? null : this._streams.groupStates.getCurrentValue(uploadGroupId, required)) ?? null;

    if (required && groupState == null) throw new Error(`Could not find upload group state for fileId: ${fileId}.`);

    return {
      uploadGroup,
      uploadGroupIndex: groupIndex,
      uploadGroupState: groupState,
      fileState,
    };
  }

  private mergeFileStateChanges(fileId: string, changes: Partial<FileState>): FileState {
    const current = this._files.get(fileId);

    if (current == null) throw new Error('File not found.');

    const newFileState = { ...current, ...changes, fileId };
    this._files.set(fileId, newFileState);

    return newFileState;
  }

  private handleFilesLoaded(event: FilesLoadedEvent) {
    for (const fileLoadDetail of event.files) {
      this._files.set(fileLoadDetail.fileId, {
        uploadGroupId: null,
        fileId: fileLoadDetail.fileId,
        fileName: fileLoadDetail.file.name,
        state: 'queued',
        fileSize: fileLoadDetail.file.size,
        compressedSize: null,
        uploadStart: null,
        uploadEnd: null,
        uploadSize: 0,
        willCompress: null,
        willAttach: null,
      });
    }

    const previousScanProgress = this._streams.scanProgress.getCurrentValue();

    this._streams.scanProgress.emit({
      ...previousScanProgress,
      total: previousScanProgress.total + event.files.length,
    });
  }

  private handleParseComplete(event: ParseCompleteEvent) {
    if (this._allServices == null) throw new Error('Cannot proceed because allServices is null.');

    if (!event.uploadEligible) {
      const previousScanProgress = this._streams.scanProgress.getCurrentValue();

      this._streams.scanProgress.emit({
        ...previousScanProgress,
        complete: previousScanProgress.complete + 1,
      });

      return;
    }

    const newGroupStates = new Map<string, UploadGroupState>(); // The key is the uploadGroupId.
    const newFileStates = new Map<string, FileState>(this._files);
    const newGroupIntervals = new Map(this._groupProcessingIntervals);

    const newUploadGroups = [...this._streams.groups.getCurrentValue()];
    const newFileContext: FileContext = {
      fileId: event.fileId,
      file: event.file,
      dicomData: event.dicomData,
    };
    const newSeriesItem: Series | null =
      event.seriesId == null
        ? null
        : {
            seriesId: event.seriesId,
            dicomData: event.dicomData,
          };

    const existingUploadGroupIndex = newUploadGroups.findIndex(
      (ui) => ui.dicomData?.StudyInstanceUID != null && ui.dicomData?.StudyInstanceUID === event.dicomData?.StudyInstanceUID,
    );
    const existingUploadGroup = existingUploadGroupIndex >= 0 ? newUploadGroups[existingUploadGroupIndex] : null;

    if (existingUploadGroup == null) {
      // Create a new upload group.
      const uploadGroup: UploadGroup = {
        uploadGroupId: crypto.randomUUID(),
        files: [newFileContext],
        attachments: [],
        series: newSeriesItem == null ? [] : [newSeriesItem],
        dicomData: event.dicomData,
        uncompressedDicomWarning: event.uncompressedDicomWarning,
        checked: true,
        patient: this._fixedPatient,
        exam: this._fixedExam,
        patientForm: this._fixedPatient ? ExamUploadService.patientModelToForm(this._fixedPatient) : ExamUploadService.patientDicomToForm(event.dicomData),
        examForm: this._fixedExam
          ? ExamUploadService.examModelToForm(this._fixedExam, this._allServices)
          : ExamUploadService.examDicomToForm(event.dicomData, this._allServices),
        patientFormState: { isDirty: false, isValid: this._fixedPatient ? true : false, isValidating: false },
        examFormState: { isDirty: false, isValid: this._fixedExam ? true : false, isValidating: false },
      };

      const groupState: UploadGroupState = {
        uploadGroupId: uploadGroup.uploadGroupId,
        compressedSize: 0,
        uploadedSize: 0,
        uploadQueuedFiles: 0,
        uploadCompleteFiles: 0,
        uncompressedUploadSize: 0,
        weightedUploadSize: 0,
      };

      const fileState: FileState = {
        ...newFileStates.get(event.fileId)!,
        uploadGroupId: uploadGroup.uploadGroupId,
      };

      const groupIntervals = new IntervalMergeCalculator();

      newGroupIntervals.set(uploadGroup.uploadGroupId, groupIntervals);
      newUploadGroups.push(uploadGroup);
      newGroupStates.set(uploadGroup.uploadGroupId, groupState);
      newFileStates.set(event.fileId, fileState);
    } else {
      // Update an existing upload group.
      const existingSeries = event.seriesId == null ? null : existingUploadGroup.series.find((s) => s.seriesId === event.seriesId);

      const uploadGroup: UploadGroup = {
        ...existingUploadGroup,
        files: [...existingUploadGroup.files, newFileContext],
        series: existingSeries == null && newSeriesItem != null ? [...existingUploadGroup.series, newSeriesItem] : existingUploadGroup.series,
        uncompressedDicomWarning: existingUploadGroup.uncompressedDicomWarning || event.uncompressedDicomWarning,
      };

      const fileState: FileState = {
        ...newFileStates.get(event.fileId)!,
        uploadGroupId: uploadGroup.uploadGroupId,
      };

      newUploadGroups[existingUploadGroupIndex] = uploadGroup;
      newFileStates.set(event.fileId, fileState);
    }

    const previousScanProgress = this._streams.scanProgress.getCurrentValue();

    this._files = newFileStates;
    this._groupProcessingIntervals = newGroupIntervals;
    this._streams.groupStates.emitMultiple(newGroupStates);
    this._streams.groups.emit(newUploadGroups);
    this._streams.scanProgress.emit({
      ...previousScanProgress,
      complete: previousScanProgress.complete + 1,
    });
  }

  private handleMatchComplete(event: MatchCompleteEvent) {
    if (this._allServices == null) throw new Error('Cannot proceed because allServices is null.');

    const newUploadGroups = [...this._streams.groups.getCurrentValue()];
    const { uploadGroup: existingGroup, uploadGroupIndex: existingGroupIndex } = this.getUploadGroupByFileId(event.fileId, true);

    newUploadGroups[existingGroupIndex] = {
      ...existingGroup,
      patient: event.patient,
      exam: event.exam,
      patientForm: event.patient ? ExamUploadService.patientModelToForm(event.patient) : existingGroup.patientForm,
      examForm: event.exam ? ExamUploadService.examModelToForm(event.exam, this._allServices) : existingGroup.examForm,
      patientFormState: { isDirty: false, isValid: event.patient != null, isValidating: false },
      examFormState: { isDirty: false, isValid: event.exam != null, isValidating: false },
    };

    this._streams.groups.emit(newUploadGroups);
  }

  private handleUploadBatchStart(event: UploadBatchStartEvent) {
    const newUploadGroupStates = new Map<string, UploadGroupState>(); // The key is the uploadGroupId.

    for (const file of event.files) {
      const { uploadGroup, uploadGroupState, fileState } = this.getUploadGroupByFileId(file.fileId, true);
      const previousUploadGroupState = newUploadGroupStates.get(uploadGroup.uploadGroupId) ?? uploadGroupState;

      const newGroupState: UploadGroupState = {
        ...previousUploadGroupState,
        uploadQueuedFiles: previousUploadGroupState.uploadQueuedFiles + 1,
        uncompressedUploadSize: previousUploadGroupState.uncompressedUploadSize + fileState.fileSize,
      };

      newUploadGroupStates.set(uploadGroup.uploadGroupId, newGroupState);

      this.mergeFileStateChanges(file.fileId, { willCompress: file.willCompress, willAttach: file.willAttach });
    }

    this._streams.groupStates.emitMultiple(newUploadGroupStates);
  }

  private handleCompressComplete(event: CompressCompleteEvent) {
    const { uploadGroupState } = this.getUploadGroupByFileId(event.fileId, true);

    const newUploadGroupState: UploadGroupState = {
      ...uploadGroupState,
      compressedSize: uploadGroupState.compressedSize + event.compressedSize,
    };

    this.mergeFileStateChanges(event.fileId, { state: 'compressed', compressedSize: event.compressedSize });
    this._streams.groupStates.emit(uploadGroupState.uploadGroupId, newUploadGroupState);
  }

  private handleCompressError(event: CompressErrorEvent) {
    this.mergeFileStateChanges(event.fileId, { state: 'error' });
  }

  private handleUploadStart(event: UploadStartEvent) {
    this.mergeFileStateChanges(event.fileId, { state: 'uploading', uploadStart: event.uploadStart });
  }

  private handleUploadProgress(event: UploadProgressEvent) {
    const { uploadGroup, uploadGroupState, fileState } = this.getUploadGroupByFileId(event.fileId, true);

    const weightedUploadSizeRatio = fileState.compressedSize == null ? 1 : fileState.compressedSize / fileState.fileSize;

    const newUploadGroupState: UploadGroupState = {
      ...uploadGroupState,
      uploadedSize: uploadGroupState.uploadedSize + event.chunkSize,
      weightedUploadSize: uploadGroupState.weightedUploadSize + event.chunkSize / weightedUploadSizeRatio,
    };

    this.mergeFileStateChanges(event.fileId, { uploadSize: event.progressSize });
    this._streams.groupStates.emit(uploadGroup.uploadGroupId, newUploadGroupState);
  }

  private handleUploadComplete(event: UploadCompleteEvent) {
    const { uploadGroup, fileState, uploadGroupState } = this.getUploadGroupByFileId(event.fileId, true);

    const newGroupState: UploadGroupState = {
      ...uploadGroupState,
      uploadQueuedFiles: fileState.willAttach ? uploadGroupState.uploadQueuedFiles : uploadGroupState.uploadQueuedFiles - 1,
    };

    this.mergeFileStateChanges(event.fileId, {
      state: 'complete',
      uploadEnd: event.uploadEnd,
      uploadSize: event.uploadSize,
    });
    this._streams.groupStates.emit(uploadGroup.uploadGroupId, newGroupState);
  }

  private handleUploadError(event: UploadErrorEvent) {
    this.mergeFileStateChanges(event.fileId, { state: 'error', uploadEnd: event.uploadEnd });
  }

  private handleFileAttached(event: FileAttachedEvent) {
    const { uploadGroup, uploadGroupState } = this.getUploadGroupByFileId(event.fileId, true);

    const newGroupState: UploadGroupState = {
      ...uploadGroupState,
      uploadQueuedFiles: uploadGroupState.uploadQueuedFiles - 1,
    };

    this._streams.groupStates.emit(uploadGroup.uploadGroupId, newGroupState);
  }
}
