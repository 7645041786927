import { memo, useCallback } from 'react';

import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

import { ImgProxy } from 'features/file/fragments/ImgProxy';

import ThumbnailIcon from '../../file/assets/thumbnail.svg?react';
import { ThumbnailColumnExamGridProps } from '../types';

export const ThumbnailColumnExamGrid = memo<ThumbnailColumnExamGridProps>(({ dataItem, width, height, onClick }) => {
  const handleClick = useCallback(() => onClick?.(dataItem), [dataItem, onClick]);

  const thumbnailFile = dataItem.files.find((file) => file.fileType === 'LINK' && file.thumbnailLocation);
  return (
    <StyledTdActionCell onClick={handleClick}>
      <StyledSpanActionContainer>
        {dataItem.imageCount !== null && dataItem.imageCount !== 0 && (
          <StyledBadge className="float-end" hasThumbnail={false}>
            {dataItem.imageCount}
          </StyledBadge>
        )}
        {thumbnailFile ? (
          <ImgProxy
            fileId={thumbnailFile.id}
            thumbnailUrl={thumbnailFile.thumbnailLocation ?? ''}
            dateModified={thumbnailFile.modified}
            width={width}
            height={height}
            isReadOnly
          />
        ) : (
          <StyledThumbnailIcon width={'24px'} height={'24px'} />
        )}
      </StyledSpanActionContainer>
    </StyledTdActionCell>
  );
});

ThumbnailColumnExamGrid.displayName = 'ThumbnailColumnExamGrid';

const StyledTdActionCell = styled.td`
  colspan: 1;
  cursor: pointer;
`;
const StyledSpanActionContainer = styled.div`
  display: flex;

  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
`;

const StyledBadge = styled(Badge)<{ hasThumbnail: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
  border-radius: 10px;
  z-index: 1;
  && {
    background-color: ${({ theme }) => theme.colors.palette.aquas[0]} !important;
    color: ${({ theme }) => theme.colors.palette.aquas[7]};
  }
`;

const StyledThumbnailIcon = styled(ThumbnailIcon)<{ width?: string; height?: string }>`
  width: ${(props) => props.width || '70px'};
  height: ${(props) => props.height || '70px'};
`;
