import { FunctionComponent } from 'react';

import { useNavigate } from 'react-router-dom';

import { ExamModel } from 'models';

import { useEvent } from 'core/hooks';

import { DefaultColumnsState, ExamActions, ExamGrid, ExamGridColumnsStateChangeEvent, ExamStatus } from 'features/exam';
import { useAppDispatch } from 'features/main/hooks';

import { PatientEditExamGridProps } from '../types';

export const PatientEditExamGrid: FunctionComponent<PatientEditExamGridProps> = ({
  isEditMode,
  donorToolbar,
  columnsState,
  dataState,
  gridData,
  handleViewFinalReport,
  handleViewAttachments,
  handleViewDicomImages,
  handleChangeStatusSubmit,
  handleDataStateChange,
  handleFilterActiveChange,

  activeExamToggle,
  handleShareChange,
  setShareToggle,
  shareToggle,
  searchValue,
  selectedExams,
  selectedStatuses,
  setColumnsState,
  setSelectedExams,
  total,
  handleShareGridClick,
  handleReadClick,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Grid Events
  const handleAddNewClick = useEvent(() => {
    // open exam add in new window for dual screen work and to not lose grid state
    window.open('/exam/add', 'AddExam', 'menubar=0,status=0,fullscreen=1');
  });

  const handleEditClick = useEvent((dataItem: ExamModel) => {
    // open exam edit in new window for dual screen work and to not lose grid state
    window.open(`/exam/${dataItem.id}/edit`, 'EditExam', 'menubar=0,status=0,fullscreen=1');
  });

  const handleExportCSVClick = useEvent(() => {
    dispatch(
      ExamActions.exportToCSV({
        dataState: {
          filter: dataState?.filter,
          sort: dataState?.sort,
        },
        quickFilter: ExamStatus.ALL.name,
      }),
    );
  });

  const handleRowDoubleClick = useEvent((exam: ExamModel) => {
    handleEditClick(exam);
  });

  const handleItemChange = useEvent((event: ExamModel) => {
    console.log(event); // TODO: Implement this for patient/share functionality.
  });

  const handleColumnsStateChange = useEvent((event: ExamGridColumnsStateChangeEvent) => {
    setColumnsState(event.columnsState);
  });

  return (
    <>
      <ExamGrid
        featureView={isEditMode ? 'Patient' : 'Share'}
        showExamToolbar={false}
        showPatientToolbar={donorToolbar}
        columnsState={columnsState ?? DefaultColumnsState}
        dataState={dataState}
        data={gridData}
        showToolbar={false}
        onExamViewFinalReportClick={handleViewFinalReport}
        onExamViewAttachmentsClick={handleViewAttachments}
        onExamViewClick={handleViewDicomImages}
        onExamReadClick={handleReadClick}
        onAddNewClick={handleAddNewClick}
        onBulkChangeStatusSubmit={handleChangeStatusSubmit}
        onDataStateChange={handleDataStateChange}
        onExamEditClick={handleEditClick}
        onExportCsvClick={handleExportCSVClick}
        onExamDoubleClick={handleRowDoubleClick}
        onExamShareChange={handleShareChange}
        onFilterActiveChange={handleFilterActiveChange}
        selectable={false}
        filterable={false}
        //setShareToggle={setShareToggle}
        //shareToggle={shareToggle}
        filterSearch={searchValue ?? ''}
        filterActive={activeExamToggle}
        selectedExams={selectedExams}
        onColumnsStateChange={handleColumnsStateChange}
        onSelectedExamsChange={setSelectedExams}
        onExamShareClick={handleShareGridClick}
      />
    </>
  );
};
