import { isPromise } from './isPromise';

export class PriorityQueue<TJob extends object> {
  static [Symbol.toStringTag]() {
    return 'PriorityQueue';
  }

  private _jobs: TJob[] = [];

  private _compare: (lhs: TJob, rhs: TJob) => number;

  private _runner: (job: TJob) => unknown;

  private _runLock = false;

  constructor(options: PriorityQueueOptions<TJob>) {
    this.destroy = this.destroy.bind(this);
    this.clear = this.clear.bind(this);
    this.enqueue = this.enqueue.bind(this);
    this.run = this.run.bind(this);
    this.runInternal = this.runInternal.bind(this);

    this._compare = options.compare;
    this._runner = options.run;
  }

  public destroy() {
    this._jobs = [];
    this._compare = () => {
      throw new Error('Cannot execute compare callback because the PriorityQueue has been destroyed.');
    };
    this._runner = () => {
      throw new Error('Cannot execute run callback because the PriorityQueue has been destroyed.');
    };
  }

  public clear() {
    this._jobs = [];
  }

  public enqueue(job: TJob | TJob[]) {
    if (Array.isArray(job)) {
      this._jobs.push(...job);
    } else {
      this._jobs.push(job);
    }
  }

  public run() {
    // Hide the fact that the queue loop is asynchronous from the caller.  It's likely better for the caller to utilize the onQueueStart and onQueueStop
    // callbacks if they need to perform any initialization or cleanup.
    this.runInternal();
  }

  private async runInternal() {
    if (this._runLock) return;

    try {
      this._runLock = true;

      while (this._jobs.length > 0) {
        // Sort the jobs based on the compare callback.
        this._jobs.sort(this._compare);

        const queueItem = this._jobs.shift()!; // Non-null assertion is safe because we just checked the length.

        try {
          const result = this._runner(queueItem);

          if (isPromise(result)) {
            await result;
          }
        } catch (ex) {
          // We need to throw the exception in order to diagnose bugs.  However we also need to keep the queue running.  So we will setup
          // the queue to continue running after a timeout.  Allowing us to immediately throw the exception and then continue processing the queue.
          window.setTimeout(() => {
            // Note that the _runLock will be reset by the finally block below.
            this.runInternal();
          });
          throw ex;
        }
      }
    } finally {
      this._runLock = false;
    }
  }
}

export type PriorityQueueOptions<TJob extends object> = {
  /** Name of the property that contains a uniquely identifying key. */
  key: keyof TJob;
  /** Callback to determine the next job to run.  This is used the same way as Array.sort().  */
  compare: (lhs: TJob, rhs: TJob) => number;
  run: (job: TJob) => unknown;
};
