import { ChangeEvent, Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { faMobileAlt, faUpload, faUserDoctor } from '@fortawesome/pro-solid-svg-icons';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { KeyValue } from '@progress/kendo-react-form';
import dayjs from 'dayjs';
import { Alert, Col, Row } from 'react-bootstrap';
import { unstable_usePrompt, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ExamModel, FileModel, PatientModel, ReadRequestModel, ServiceModel, TatModel, UserModel } from 'models';
import { PatientAgeRangeModel } from 'models/PatientAgeRangeModel';

import { ApiRouteService } from 'core/api';
import { Field, Form, FormElement, FormValidatorsService, emailList } from 'core/forms';
import { useEvent } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Accordion, Button, ButtonVariants, Dialog, Dropdown, Icon, Input, Page, PageHeader, TextArea, Tooltip } from 'core/ui';

import { apiClient } from 'features/api';
import { useAccessTokenSnapshot, useCurrentUser } from 'features/auth';
import { getServiceDetailsFromForm } from 'features/dynamic-forms/services/service-details-service';
import { CoordinatorInfoAccordion, ExamTypeService } from 'features/exam';
import { ExamReadSubmitType } from 'features/exam-reading/constants';
import { ExamDetailsAccordion } from 'features/exam-reading/fragments/ExamDetailsAccordion';
import { PathologyObservationsAccordion } from 'features/exam-reading/fragments/PathologyObservationsAccordion';
import { PulmonologyObservationsAccordion } from 'features/exam-reading/fragments/PulmonologyObservationsAccordion';
import { ExamReadsDataService } from 'features/exam-reading/services';
import { ExamTypes } from 'features/exam/constants';
import { useExamFiles } from 'features/exam/hooks';
import { FileActions, FileSelectSidebar, FileViewer } from 'features/file';
import ExpandSidebarIcon from 'features/file/assets/left-arrow.svg?react';
import CollapseSidebarIcon from 'features/file/assets/right-arrow.svg?react';
import { useAppDispatch } from 'features/main/hooks';
import { InitialValues, PatientEditService, PatientUtils } from 'features/patient';
import { ShareType } from 'features/patient/constants';
import { PatientFormValues } from 'features/patient/types';
import { useUserSettings } from 'features/settings';
import { UploadStatusConstants } from 'features/share/constants';
import { QRScanModal } from 'features/upload-exams/fragments/QRScanModal';

import { ADD_READ_REQUEST_ACTIVE_KEYS, EDIT_READ_REQUEST_ACTIVE_KEYS, InitialValuesReadRequest, isFormInEditModeConstant } from '../constants';
import { ReadRequestFormValues } from '../types/ReadRequestFormValues';
import { ReadRequestPatientAccordion } from './ReadRequestPatientAccordion';

const ReadRequestFormInner: FunctionComponent<{
  readRequestFormValues: ReadRequestFormValues | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (key: string) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueSetter: (key: string, value: any) => void;
  isFormInEditMode: boolean;
  patientFormValue: PatientFormValues;
  exam: ExamModel | undefined;
  errors: KeyValue<string>;
  setCurrentFile?: Dispatch<SetStateAction<null>>;
  isSidebarExpanded: boolean;
}> = ({ readRequestFormValues, valueGetter, valueSetter, isSidebarExpanded, patientFormValue, exam, errors, setCurrentFile }) => {
  const isResultFormVisible = true;
  const { currentUser } = useCurrentUser();

  const studyType = valueGetter('service.description');
  const organId = valueGetter('organ');
  const serviceId = valueGetter('service.id');
  let locationTats: TatModel[] = [];

  if (exam?.location?.taTs) {
    if (organId) {
      locationTats = exam?.location?.taTs.filter((tat) => tat.serviceId === serviceId && tat.organ === organId);
    } else {
      locationTats = exam?.location?.taTs.filter((tat) => !tat.serviceId || tat.serviceId === serviceId);
    }
  }

  const defaultTat = locationTats.length === 1 ? locationTats[0] : locationTats.find((tat) => tat.value === exam?.location?.defaultTAT);

  const selectedLocation = exam?.location;

  // age range of the patient
  const [defaultTats, setDefaultTats] = useState<TatModel[]>();
  const [isQrModalVisible, setIsQrModalVisible] = useState<boolean>(false);
  const [linkId, setLinkId] = useState<string | null>();

  useEffect(() => {
    valueSetter('sla', { value: defaultTat ?? null });
  }, [defaultTat, valueGetter, valueSetter]);

  useEffect(() => {
    const fetchTats = async () => {
      const tats = await apiClient.tatClient.getDefaultTATs();
      setDefaultTats(tats);
    };

    fetchTats();
  }, []);

  const handleTatChange = () => {
    valueSetter('requestedDate', {
      value: dayjs().utc().tz('America/Los_Angeles').format('YYYY-MM-DDTHH:mm:ss'),
    });
    valueSetter('requestTime', {
      value: dayjs().utc().tz('America/Los_Angeles').format('YYYY-MM-DDTHH:mm:ss'),
    });
  };

  const handleQrModalClose = useEvent(() => {
    setIsQrModalVisible(false);
    setLinkId(null);
  });

  const isUserModel = (user: UserModel | 'anonymous' | undefined): user is UserModel => {
    return (user as UserModel)?.email !== undefined;
  };

  // age range of the patient
  const [ageRanges, setAgeRanges] = useState<PatientAgeRangeModel[]>();

  useEffect(() => {
    const fetchAgeRanges = async () => {
      const patientAgeRangeResponse = await apiClient.patientClient.getPatientAgeRange();

      setAgeRanges(patientAgeRangeResponse);
    };

    fetchAgeRanges();
  }, [selectedLocation, valueGetter, valueSetter]);

  useEffect(() => {
    if (readRequestFormValues) {
      if (readRequestFormValues?.dynamicFormFields) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataFormFields = ExamReadsDataService.mapApiDataToExam(readRequestFormValues.dynamicFormFields) as any;
        valueSetter('Observations', {
          value: dataFormFields.Observations,
        });
        valueSetter('Results', {
          value: dataFormFields.Results,
        });
      }
    }
  }, [readRequestFormValues, valueSetter]);

  useEffect(() => {
    const defaultCoordinator = isUserModel(currentUser)
      ? {
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.email,
          mobileNumber: currentUser.phone,
        }
      : {};

    // map patient
    valueSetter('patient', { value: readRequestFormValues?.patient });
    // map coordinator
    valueSetter('coordinator', { value: defaultCoordinator });
    // map study
    valueSetter('service', { value: readRequestFormValues?.service });
    valueSetter('organ', { value: readRequestFormValues?.organ });
    valueSetter('laterality', { value: readRequestFormValues?.laterality });
    valueSetter('biopsyType', { value: readRequestFormValues?.biopsyType });
    valueSetter('slidePreparation', {
      value: readRequestFormValues?.slidePreparation,
    });
    valueSetter('description', {
      value: readRequestFormValues?.description,
    });
    valueSetter('notes', {
      value: readRequestFormValues?.notes,
    });
    valueSetter('emailReportDestination', {
      value: isUserModel(currentUser) ? currentUser?.email : '',
    });
    valueSetter('requestNotes', {
      value: readRequestFormValues?.notes,
    });
    valueSetter('patientObservation.medicationNotes', {
      value: readRequestFormValues?.medicationIndication,
    });
  }, [
    currentUser,
    ageRanges,
    readRequestFormValues,
    readRequestFormValues?.biopsyType,
    readRequestFormValues?.coordinator,
    readRequestFormValues?.description,
    readRequestFormValues?.emailReportDestination,
    readRequestFormValues?.laterality,
    readRequestFormValues?.notes,
    readRequestFormValues?.organ,
    readRequestFormValues?.patient,
    readRequestFormValues?.requestNotes,
    readRequestFormValues?.service,
    readRequestFormValues?.slidePreparation,
    valueGetter,
    valueSetter,
  ]);

  useEffect(() => {
    // set the sla to the form
    if (selectedLocation !== undefined && selectedLocation !== null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      locationTats.forEach((tat: TatModel) => {
        if (tat.value === (valueGetter('sla') as number)) {
          valueSetter('sla', { value: tat });
        }
      });
    }
  }, [selectedLocation, valueGetter, valueSetter]);

  const [age, setAge] = useState<string>(PatientUtils.calculateAgeFromDob(patientFormValue?.dob ?? null));
  const [isLinkCreating, setIsLinkCreating] = useState<boolean>(false);
  const filesInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const [connection, setConnection] = useState<HubConnection>();
  const accessToken = useAccessTokenSnapshot().accessToken ?? '';

  useEffect(() => {
    if (accessToken && isQrModalVisible && !connection) {
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${ApiRouteService.getCompumedApiBaseRoute()}/hub/upload`, { accessTokenFactory: () => accessToken })
        .withAutomaticReconnect()
        .build();
      setConnection(newConnection);
    }
  }, [accessToken, isQrModalVisible, connection]);

  useEffect(() => {
    if (connection) {
      connection.start().then(() => {
        console.log('Connection started');
      });
    }
  }, [connection]);

  useEffect(() => {
    if (connection && exam?.id) {
      connection.on('uploadStatus', (message: string) => {
        if (exam?.id && message === UploadStatusConstants.uploaded) {
          dispatch(FileActions.getFilesByExamId(exam?.id)).then((f) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            const lastFile = f.payload.reduce((prev, current) => (prev.created > current.created ? prev : current));
            if (lastFile && setCurrentFile) {
              setCurrentFile(lastFile);
            }
          });
        }
      });
    }
  }, [connection, dispatch, exam, setCurrentFile]);

  const patientFormValues: PatientFormValues = useMemo(
    () => ({
      ...(patientFormValue ?? InitialValues.donorForm),
    }),
    [patientFormValue],
  );

  const onUploadFilesClick = () => {
    filesInputRef.current?.click?.();
  };

  const onUploadFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;

    if (selectedFiles == null) throw new Error('No files selected');

    const formData = new FormData();

    for (const file of selectedFiles) {
      formData.append('files', file);
    }

    try {
      dispatch(FileActions.add({ files: formData, examId: exam?.id })).then((f) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const files = f.payload.files;
        const lastFile = files[Object.keys(files).pop()];

        if (lastFile && setCurrentFile) {
          setCurrentFile(lastFile);
        }
      });

      NotificationsService.displaySuccess('Files uploaded successfully');
    } catch (e) {
      NotificationsService.displaySuccess('Files failed to upload');
    }
  };

  const handleOnChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => {
      setAge(PatientUtils.calculateAgeFromDob(valueGetter('patient.dob')));

      // force the value to be matched by dob
      valueSetter('patient.ageRange', {
        value: PatientUtils.calculateAgeRange(ageRanges, PatientUtils.calculateAgeFromDob(valueGetter('patient.dob'))),
      });

      // pass the updated value here...
      valueSetter('patient', {
        value: data,
      });
    },
    [ageRanges, valueGetter, valueSetter],
  );

  const onQrClick = async () => {
    setIsLinkCreating(true);
    try {
      const linkId = crypto.randomUUID();
      if (exam?.id && exam?.id > 0) {
        const expireOn = dayjs().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
        await apiClient.studyShare.addStudyShare({
          id: 0,
          linkId: linkId,
          email: null,
          sharePassword: null,
          shareType: ShareType.Link,
          sharePatientId: null,
          studyShareExams: [{ exam_id: exam.id }],
          expireOn: expireOn,
          dateCreated: '',
          message: null,
          bypassSplashPage: true,
        });
      }
      setLinkId(linkId);
      setIsQrModalVisible(true);
    } finally {
      setIsLinkCreating(false);
    }
  };

  const isMedication =
    ExamTypeService.isUS(studyType) || ExamTypeService.isUSSR(studyType) || ExamTypeService.isSR(studyType) || ExamTypeService.isEcho(studyType);

  const reportDestinationValidations = useMemo(() => {
    return [emailList(';'), FormValidatorsService.required];
  }, []);

  return (
    <Accordion defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7']} alwaysOpen>
      {exam && <ReadRequestPatientAccordion exam={exam} isExpanded={isSidebarExpanded} />}
      {/* // study panel  */}
      <ExamDetailsAccordion
        eventKey="4"
        exam={readRequestFormValues}
        studyType={studyType}
        valueGetter={valueGetter}
        valueSetter={valueSetter}
        isReadOnly
        isSidebarExpanded={isSidebarExpanded}
      />
      {/* // biopsy indications  */}
      {studyType && ExamTypes.PULMONOLOGY.includes(studyType) && <PulmonologyObservationsAccordion eventKey="5" isFormDisabled={!isResultFormVisible} />}
      {studyType && ExamTypes.PATHOLOGY.includes(studyType) && (
        <PathologyObservationsAccordion eventKey="5" isFormDisabled={!isResultFormVisible} valueGetter={valueGetter} />
      )}

      {/* // request  */}
      <Accordion.Item eventKey="1">
        <Accordion.Header title="Request" Icon={ReadRequestIcon}></Accordion.Header>
        <Accordion.Body>
          <StyledRow>
            <Col className={isSidebarExpanded ? 'col-4' : 'col-12'}>
              <Tooltip text="To enter multiple email addresses, separate each address with a semicolon (;).">
                <Field component={Input} label="Email/Report Destination" name="emailReportDestination" required validator={reportDestinationValidations} />
              </Tooltip>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col className={isSidebarExpanded ? 'col-8' : 'col-12'}>
              <Field component={TextArea} label="Request Notes" name="requestNotes" />
            </Col>
          </StyledRow>
          {isMedication && (
            <StyledRow>
              <Col className={isSidebarExpanded ? 'col-8' : 'col-12'}>
                <Field component={TextArea} label="Medications" name="patientObservation.medicationNotes" />
              </Col>
            </StyledRow>
          )}
          <StyledRow>
            <Col className={isSidebarExpanded ? 'col-2' : 'col-5'}>
              <Field
                component={Dropdown}
                data={!Array.isArray(locationTats) || !locationTats.length ? defaultTats : locationTats}
                required
                validator={FormValidatorsService.required}
                label="Turn Around Time"
                name="sla"
                textField="name"
                onChange={handleTatChange}
              />
            </Col>
          </StyledRow>
        </Accordion.Body>
      </Accordion.Item>

      {/* // primary contact */}
      <CoordinatorInfoAccordion eventKey="3" coordinator={valueGetter('coordinator')} isEditMode isSidebarExpanded={isSidebarExpanded} />
      {exam?.id && linkId && <QRScanModal show={isQrModalVisible} onClose={handleQrModalClose} examId={exam.id} linkId={linkId} />}
      <Accordion.Item eventKey="7">
        <Accordion.Header title="Upload" Icon={UploadIcon}></Accordion.Header>
        <Accordion.Body>
          <StyledRow>
            <Col className="col-4">
              <StyledUploadDiv>
                <Button
                  variant={ButtonVariants.SECONDARY}
                  onClick={onUploadFilesClick}
                  iconClass="fa fa-plus"
                  disabled={isQrModalVisible || !exam?.id || exam?.id == 0}
                >
                  Upload File(s)
                </Button>
                <input
                  accept=".pdf,.xml,.png,.jpg,.tiff,.szi,.sv,.jpeg"
                  id="file"
                  multiple
                  onChange={onUploadFilesChange}
                  ref={filesInputRef}
                  style={{ display: 'none' }}
                  type="file"
                />
                <StyledUploadButton
                  variant={ButtonVariants.SECONDARY}
                  onClick={onQrClick}
                  disabled={isQrModalVisible || !exam?.id || exam?.id == 0 || isLinkCreating}
                >
                  <Icon icon={faMobileAlt}></Icon>
                  Mobile Upload
                </StyledUploadButton>
              </StyledUploadDiv>
            </Col>
          </StyledRow>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

ReadRequestFormInner.displayName = 'ReadRequestFormInner';

export const ReadRequestFormEdit: FunctionComponent = () => {
  const { id: idParam } = useParams();

  const id = useMemo(() => {
    const parsedId = idParam != null ? parseInt(idParam, 10) : Number.NaN;
    return Number.isNaN(parsedId) || idParam === undefined ? undefined : parsedId;
  }, [idParam]);

  const navigate = useNavigate();

  const [srcExam, setSrcExam] = useState<ExamModel | undefined>();
  const [donorForm, setDonorForm] = useState(InitialValues.donorForm);
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [showValidationDialog, setShowValidationDialog] = useState<boolean>(false);
  const { donorPageUrl, reactPatientFormPage } = useUserSettings(true);

  const [readRequestFormValues, setReadRequestFormValues] = useState<ReadRequestFormValues>(InitialValuesReadRequest);

  unstable_usePrompt({
    message: 'Are you sure you want to leave this form?',
    when: ({ currentLocation, nextLocation }) => !isFormSubmitted && currentLocation.pathname !== nextLocation.pathname,
  });

  useEffect(() => {
    const fetchExamData = async () => {
      const response = await apiClient.exams.getExamById(id!, true);

      setSrcExam(response);
      const patient = { ...response?.patient, location: response?.location };
      setDonorForm(PatientEditService.copyModelToForm(patient as PatientModel));
    };
    if (id !== undefined) fetchExamData();
  }, [id]);

  useEffect(() => {
    if (srcExam) {
      setReadRequestFormValues({
        ...srcExam,
        id: 0,
        requestNotes: '',
        turnAroundTime: 0,
        locationType: srcExam.location?.locationType?.name ?? '',
        dynamicFormFields: srcExam?.dynamicFormFields,
        emailReportDestination: srcExam?.coordinator?.email,
      });
    }
  }, [srcExam]);

  const { accessToken } = useAccessTokenSnapshot();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formMeetsCustomRequirements = async (formData: any) => {
    if (showValidationDialog) {
      return true;
    }

    if (srcExam?.id && ExamTypeService.isXA(formData?.service?.description)) {
      const files = await apiClient.filesClient.getFilesByExamId(srcExam.id, 'msal-required');
      return files.some((file) => ['PDF', 'JPEG', 'JPG'].includes(file.fileType.toUpperCase()));
    }
    return true;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = useEvent(async (data: any) => {
    // prepare the dynamic form
    const serviceDetails = getServiceDetailsFromForm(data);

    const { readData } = ExamReadsDataService.getSubmitData({
      id,
      Observations: data.Observations,
      Results: data.Results,
      ServiceDetails: serviceDetails,
    });

    // prepare models
    const payload: ReadRequestModel = {
      id: 0,
      requestStatusId: 0,
      examRequestId: 0,
      examId: srcExam?.id ?? 0,
      location: srcExam?.location as unknown as ReadRequestModel['location'], // TODO: This cast is bad.  It needs to be removed.
      patient: data.patient,
      service: data.service,
      serviceId: data.service.id,
      organ: data.organ,
      laterality: data.laterality,
      biopsyType: data.biopsyType,
      slidePreparation: data.slidePreparation,
      histologyQuality: data.Results !== undefined ? data.Results.histologyQuality : '',
      readingType: data.Results !== undefined ? data.Results.readingType : '',
      studyDescription: data.description,
      studyNotes: data.notes,
      studyDateTime: srcExam?.studyDate as unknown as Date, // TODO: This cast is bad.  It needs to be removed.
      dynamicForms: readData as unknown as ReadRequestModel['dynamicForms'], // TODO: This cast is bad.  It needs to be removed.
      requestNotes: data.requestNotes,
      sla: data.sla,
      coordinator: data.coordinator,
      emailReportDestination: data.emailReportDestination ?? data.coordinator?.email,
      patientObservation: data.patientObservation,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      examRequests: Object.keys(data)
        .filter((key) => key.startsWith('services')) // Filter keys that start with "services"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        .flatMap(
          (key) =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            data[key]?.map((service: ServiceModel) => ({
              serviceId: service.id,
              examId: srcExam?.id ?? 0,
              availableTATId: null, // TODO populate TAT from service selection
            })) || [],
        ),
    };

    try {
      setIsFormSubmitted(true);
      setIsFormDisabled(true);
      if (!(await formMeetsCustomRequirements(data))) {
        setShowValidationDialog(true);
        return;
      } else {
        setShowValidationDialog(false);
      }
      await apiClient.exams.saveReadRequest(payload);
      NotificationsService.displaySuccess('Read request saved');
      if (srcExam?.patient?.id && srcExam?.patient?.id > 0) {
        navigateToDonorPage();
      }
    } catch (e) {
      console.error(e);
      NotificationsService.displayError('Read request save failed');
    } finally {
      setIsFormDisabled(false);
    }
    return { ...data, patient: !data.patient, sla: data.sla };
  });

  const navigateToDonorPage = () => {
    let url = '';
    if (srcExam?.location?.locationType?.name === 'OPO') {
      const donorUrl = donorPageUrl ?? '/Patient-2/Edit';
      url = `${donorUrl}/${srcExam?.patient?.id}`;
    } else {
      url = '/Home';
    }

    if (reactPatientFormPage) {
      navigate(url);
    } else {
      window.location = url as unknown as typeof window.location;
    }
  };

  const { files, currentFile, setCurrentFile } = useExamFiles(id, srcExam?.statusType as unknown as string, accessToken);
  const [formKey, setFormKey] = useState(1);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const formRef = useRef<HTMLDivElement>(null);
  // cast the value from any to FileModel
  const currentFileModel = currentFile as unknown as FileModel;

  const getLabelText = (forValue: string | null) => {
    if (formRef?.current) {
      const labelElement = formRef.current.querySelector(`label[for="${forValue}"]`);
      return labelElement ? labelElement.textContent : '';
    }
    return '';
  };

  const getErrorMessage = (errors: KeyValue<string>) => {
    return (
      `Please fix the following field(s): ` +
      Object.entries(errors)
        .map(([key, value]) => {
          const labelText = getLabelText(key);
          return value !== '' ? `${labelText} - ${value} ` : '';
        })
        .join('')
    );
  };

  const resetForm = () => {
    navigateToDonorPage();
  };

  return (
    <Page className="styledPage">
      <PageHeader title="Read Request" />
      <StyledContainer ref={formRef} isSidebarCollapsed={isSidebarCollapsed}>
        <StyledAccordion
          className="styledAccordion"
          alwaysOpen
          defaultActiveKey={isFormInEditModeConstant ? EDIT_READ_REQUEST_ACTIVE_KEYS : ADD_READ_REQUEST_ACTIVE_KEYS}
        >
          <StyledForm
            initialValues={readRequestFormValues}
            id="exam-read-request-sidebar-form"
            key={formKey}
            onSubmit={handleSubmit}
            ignoreModified
            render={({ onChange: valueSetter, valueGetter, errors }) => {
              const isXA = ExamTypeService.isXA(valueGetter('service')?.description);
              return (
                <StyledFormElement>
                  <StyledDiv>
                    <ReadRequestFormInner
                      readRequestFormValues={readRequestFormValues}
                      valueGetter={valueGetter}
                      valueSetter={valueSetter}
                      patientFormValue={donorForm}
                      exam={srcExam}
                      isFormInEditMode
                      errors={errors}
                      setCurrentFile={setCurrentFile}
                      isSidebarExpanded={isSidebarCollapsed}
                    />
                    {/*<StyledHideSidebarDiv $isCollapsed={isSidebarCollapsed} onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}>*/}
                    {/*  {isSidebarCollapsed ? <ExpandSidebarIcon /> : <CollapseSidebarIcon />}*/}
                    {/*</StyledHideSidebarDiv>*/}
                  </StyledDiv>
                  <StyledErrorSummaryDiv>
                    {isXA && (
                      <div className={'k-messagebox k-messagebox-error'}>
                        Pressure data from the Cath lab is required. You can upload it as a PDF/JPEG attachment using the upload or mobile upload buttons above
                      </div>
                    )}
                    {Object.values(errors).some((value) => value !== '') && <div className={'k-messagebox k-messagebox-error'}>{getErrorMessage(errors)}</div>}
                  </StyledErrorSummaryDiv>
                  <StyledButtonsRow>
                    <Col className="col-8">
                      <>
                        <StyledCancelButton variant={ButtonVariants.SECONDARY} type="button" onClick={resetForm} form="exam-read-request-sidebar-form">
                          Cancel
                        </StyledCancelButton>
                        <StyledSaveButton disabled={isFormDisabled} id={ExamReadSubmitType.SAVE} type="submit" form="exam-read-request-sidebar-form">
                          Submit Request
                        </StyledSaveButton>
                        {showValidationDialog && (
                          <StyledDialog title="Please Confirm" onClose={() => setShowValidationDialog(!showValidationDialog)}>
                            <p>
                              We see you have not uploaded a PDF file. Pressure data from the Cath lab is required. You can upload using the upload or mobile
                              upload buttons below. If you&apos;d still like to submit anyways, please click &apos;Yes&apos; below.
                            </p>
                            <DialogActionsBar>
                              <Button onClick={() => setShowValidationDialog(!showValidationDialog)} variant={ButtonVariants.SECONDARY}>
                                No
                              </Button>
                              <Button type="submit" form="exam-read-request-sidebar-form">
                                Yes
                              </Button>
                            </DialogActionsBar>
                          </StyledDialog>
                        )}
                      </>
                    </Col>
                  </StyledButtonsRow>
                </StyledFormElement>
              );
            }}
          />
        </StyledAccordion>
        {!isSidebarCollapsed && (
          <StyledViewerContainer>
            {currentFileModel && <FileViewer file={currentFileModel} overrideMultiMonitor />}
            {files.length > 0 && (
              <FileSelectSidebar
                files={files}
                handleFileSelect={(file) => setCurrentFile(file)}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                handleAddNewFileClick={() => {}}
                selectedFile={currentFile}
                showDeleteBtn
                examType={srcExam?.service?.description}
                hideUploadButton={true}
              />
            )}
          </StyledViewerContainer>
        )}
      </StyledContainer>
    </Page>
  );
};

const ReadRequestIcon: FunctionComponent = () => {
  return <StyledIcon fixedWidth={false} icon={faUserDoctor} />;
};

const UploadIcon: FunctionComponent = () => {
  return <StyledIcon fixedWidth={false} icon={faUpload} />;
};

ReadRequestFormEdit.displayName = 'ReadRequestFormEdit';
const mediaMaxWidth = '1024px';

const StyledViewerContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 95vh;
  width: 100%;
  justify-content: flex-end;
`;

const StyledContainer = styled(Page)<{ isSidebarCollapsed: boolean }>`
  display: grid;
  grid-template-columns: ${({ isSidebarCollapsed }) => (isSidebarCollapsed ? '1fr' : '600px 1fr')};
  grid-template-rows: 1fr;
  overflow: hidden;

  label.k-label,
  div,
  span {
    font-size: 1.1rem;
  }

  @media (max-width: ${mediaMaxWidth}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    #sidebarArrowIcon {
      top: 75%;
    }

    & > :nth-child(1) {
      grid-column: 1 / -1;
      grid-row: 1;
      height: 100dvh;
      max-height: 100dvh;
      overflow-y: auto;
    }

    & > :nth-child(2) {
      height: 100dvh;
      overflow-y: auto;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  height: 100%;

  label:not(.k-checkbox-label) {
    font-weight: 600;
  }

  //.accordion {
  //  margin-right: 7px;
  //}

  .svg-inline--fa {
    color: ${({ theme }) => theme.colors.palette.blues[4]};
  }

  .accordion-item {
    background: ${({ theme }) => theme.colors.backgroundPrimary};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.palette.blues[1]} !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    margin: ${({ theme }) => theme.space.spacing20};

    .accordion-body {
      padding-top: 0;
    }

    .accordion-body .row {
      // background: ${({ theme }) => theme.colors.palette.blues[0]};
    }
  }

  @media (max-width: ${mediaMaxWidth}) and (orientation: landscape) {
    display: block;
    height: 100%;
  }
`;

const StyledForm = styled(Form)`
  height: 100%;
  display: flex;
`;

const StyledFormElement = styled(FormElement)`
  display: grid;
  grid-template-rows: auto min-content min-content;
  overflow-x: auto;
  overflow-y: auto;
  height: auto;
  width: 100%;
  max-height: none;

  @media (max-width: ${mediaMaxWidth}) {
    grid-template-rows: auto min-content 100px;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.space.spacing20};

  label.k-label {
    margin-bottom: 0;
  }
`;

const StyledButtonsRow = styled(Row)`
  background: white;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  margin-right: 0;
  padding: ${({ theme }) => theme.space.spacing30};
  margin-top: auto;
`;

const StyledDiv = styled.div`
  background: white;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  position: relative;
`;

const StyledCancelButton = styled(Button)`
  && {
    height: 3em;
  }
`;

const StyledSaveButton = styled(Button)`
  margin-left: ${({ theme }) => theme.space.spacing20};
  && {
    height: 3em;
  }
`;

const StyledUploadButton = styled(Button)`
  margin-left: ${({ theme }) => theme.space.spacing20};

  @media (max-width: ${mediaMaxWidth}) {
    display: none; /* Hide the button on mobile devices */
  }
`;

const StyledUploadDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.space.spacing20};
`;

const StyledDialog = styled(Dialog)`
  width: 400px;
  @media (max-width: ${mediaMaxWidth}) {
    top: 25%;
    width: 200px;
  }
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledErrorSummaryDiv = styled.div`
  margin-top: auto;
`;

const StyledHideSidebarDiv = styled.div<{ $isCollapsed: boolean }>`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  display: flex;
  height: 4rem;
  justify-content: center;
  position: absolute;
  top: calc(50% + 50px);
  transition: opacity 2.6s linear;
  width: 1.2rem;
  z-index: 10;
  right: 0;
`;
